import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './VideoChatSection.css';

const languageFlags = {
  Russian: '/images/icon_flag-ru.svg',
  Spanish: '/images/mexico_flag.png',
  Japanese: '/images/icon_flag-ja.svg',
  Portuguese: '/images/icon_flag-pt.svg',
  French: '/images/icon_flag-fr.svg',
  German: '/images/icon_flag-de.svg',
  Italian: '/images/icon_flag-it.svg',
  Mandarin: '/images/icon_flag-zh.svg',
  Arabic: '/images/icon_flag-ar.svg',
  Cantonese: '/images/icon_flag-hk.png',
  Korean: '/images/icon_flag-ko.svg',
  Tagalog: '/images/icon_flag_pp.png',
  Hindi: '/images/icon_flag_in.png',
  Greek: '/images/icon_flag_gr.png',
};

const VideoChatSection = ({ userLanguages, onAddLanguages }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (userLanguages.length > 0) {
      setSelectedLanguage(userLanguages[0]);
    }
  }, [userLanguages]);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    navigate(`/connect/${language.toLowerCase()}`);
  };

  return (
    <div className="video-chat-section">
      <div className="video-chat-content">
        <h2 className="video-chat-title">
          <span className="video-chat-primary-text">
            {t('discover.videoChat.title.primary')}
          </span>
        </h2>
        <img 
          src="/images/home-page-video-chat.png" 
          alt={t('discover.videoChat.alt.icon')} 
          className="video-chat-icon" 
        />
      </div>
      <p className="video-chat-description">
        {t('discover.videoChat.description')}
      </p>
      <div className="video-chat-language-selector">
        <div className="video-chat-flag-slider">
          {userLanguages.map((language) => (
            <img
              key={language}
              src={languageFlags[language]}
              alt={t('discover.videoChat.alt.flag', { language })}
              className={`video-chat-language-flag ${selectedLanguage === language ? 'selected' : ''}`}
              onClick={() => handleLanguageSelect(language)}
            />
          ))}
        </div>
        <button 
          className="add-language-button" 
          onClick={onAddLanguages}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default VideoChatSection;
