import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import './CreateThreadModal.css';

const popularLocations = [
  { emoji: '🇯🇵', name: 'Japan' },
  { emoji: '🇮🇹', name: 'Italy' },
  { emoji: '🇫🇷', name: 'France' },
  { emoji: '🇬🇧', name: 'UK' },
  { emoji: '🇹🇭', name: 'Thailand' }
];

const CreateThreadModal = ({ isOpen, onClose, onSubmit, threadCategories }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = React.useState({
    title: '',
    content: '',
    location: '',
    category: 'question'
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const now = new Date();
    onSubmit({
      ...formData,
      createdAt: now.toISOString()
    });
  };

  const handleEmojiSelect = (emoji) => {
    const locationText = formData.location.replace(/[\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF]\s?/g, '').trim();
    setFormData({
      ...formData,
      location: locationText ? `${emoji.native} ${locationText}` : emoji.native
    });
    setShowEmojiPicker(false);
  };

  const handleLocationSelect = (location) => {
    setFormData({
      ...formData,
      location: `${location.emoji} ${location.name}`
    });
  };

  return (
    <div className="modal-overlay">
      <div className="create-thread-modal">
        <div className="modal-header">
          <h2>{t("Travel.form.createNewThread")}</h2>
          <button className="close-button" onClick={onClose}>{t("common.close")}</button>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Category Selection */}
          <div className="form-section">
            <h3 className="section-label">{t("Travel.form.category")}</h3>
            <div className="category-options">
              {Object.entries(threadCategories).map(([key, category]) => (
                <div 
                  key={key}
                  className={`category-option ${formData.category === key ? 'selected' : ''}`}
                  onClick={() => setFormData({...formData, category: key})}
                >
                  <span className="category-icon">{category.icon}</span>
                  <div className="category-info">
                    <h4>{t(`Travel.categories.${key}.label`)}</h4>
                    <p>{t(`Travel.categories.${key}.description`)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Location Section */}
          <div className="form-section">
            <h3 className="section-label">{t("Travel.form.location")}</h3>
            <div className="location-input-wrapper">
              <input
                type="text"
                placeholder={t("Travel.form.placeholders.location")}
                value={formData.location}
                onChange={(e) => setFormData({...formData, location: e.target.value})}
              />
              <button 
                type="button"
                className="emoji-picker-button"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              >
                <span className="flag-icon">🏳️</span>
                <span className="flag-text">{t("Travel.form.locationPicker.addFlag")}</span>
              </button>
              
              {showEmojiPicker && (
                <div className="emoji-picker-container">
                  <Picker 
                    data={data}
                    onEmojiSelect={handleEmojiSelect}
                    theme="dark"
                    skinTonePosition="none"
                    categories={['flags']}
                    maxFrequentRows={0}
                  />
                </div>
              )}
            </div>

            <div className="popular-locations">
              {popularLocations.map((location) => (
                <span 
                  key={location.name}
                  className={`suggestion ${formData.location === `${location.emoji} ${location.name}` ? 'selected' : ''}`}
                  onClick={() => handleLocationSelect(location)}
                >
                  {location.emoji} {location.name}
                </span>
              ))}
            </div>
          </div>

          {/* Title Section */}
          <div className="form-section">
            <h3 className="section-label">{t("Travel.form.title")}</h3>
            <input
              type="text"
              placeholder={t("Travel.form.placeholders.title")}
              value={formData.title}
              onChange={(e) => setFormData({...formData, title: e.target.value})}
            />
            <p className="input-tip">
              {t("Travel.form.tips.title")}
            </p>
          </div>

          {/* Content Section */}
          <div className="form-section">
            <h3 className="section-label">{t("Travel.form.content")}</h3>
            <textarea
              placeholder={t("Travel.form.placeholders.content")}
              value={formData.content}
              onChange={(e) => setFormData({...formData, content: e.target.value})}
              rows="8"
            />
            <p className="input-tip">
              {t("Travel.form.tips.content")}
            </p>
          </div>

          <div className="form-actions">
            <button type="button" className="cancel-btn" onClick={onClose}>
              {t("Travel.form.cancel")}
            </button>
            <button type="submit" className="submit-btn">
              {t("Travel.form.submit")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateThreadModal; 