import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes, faGift, faCheck, faComments } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import SpeakeasyProModal from '../SpeakeasyProModal/SpeakeasyProModal';
import './DailyLimitModal.css';

function DailyLimitModal({ isOpen, onClose, nextAvailableTime, isAIFeature }) {
  const { t } = useTranslation();
  const [showProModal, setShowProModal] = useState(false);

  if (!isOpen) return null;

  const handleProClick = () => {
    setShowProModal(true);
  };

  return (
    <>
      <div className="wordpack-intro-overlay">
        <div className="wordpack-intro-modal">
          <button className="intro-close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <div className="intro-content">
            <div className="intro-icon">
              <FontAwesomeIcon icon={isAIFeature ? faComments : faClock} />
            </div>

            <h2 className="intro-title">
              {isAIFeature ? t('dailyLimitModal.aiTitle') : t('dailyLimitModal.title')}
            </h2>
            
            <div className="intro-body">
              {!isAIFeature && (
                <p>{t('dailyLimitModal.message', { time: nextAvailableTime })}</p>
              )}
              {isAIFeature && (
                <p>{t('dailyLimitModal.aiMessage')}</p>
              )}
              
              <div className="pro-advertisement">
                <p className="pro-message">
                  <FontAwesomeIcon icon={faGift} className="pro-icon" />
                  {isAIFeature ? t('dailyLimitModal.aiProMessage') : t('dailyLimitModal.proMessage')}
                </p>
                <ul className="pro-features">
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    {t('dailyLimitModal.features.unlimited')}
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    {t('dailyLimitModal.features.customTopics')}
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    {t('dailyLimitModal.features.aiTutor')}
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} className="check-icon" />
                    {t('dailyLimitModal.features.progress')}
                  </li>
                </ul>
                <button 
                  className="pro-upgrade-button"
                  onClick={handleProClick}
                >
                  {t('dailyLimitModal.proButton')}
                </button>
              </div>
            </div>

            <button className="intro-next-button" onClick={onClose}>
              {t('dailyLimitModal.closeButton')}
            </button>
          </div>
        </div>
      </div>

      <SpeakeasyProModal 
        isOpen={showProModal}
        onRequestClose={() => setShowProModal(false)}
      />
    </>
  );
}

export default DailyLimitModal; 