import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './Home.css';
import { FaInstagram, FaTwitter, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import LanguageSelector from '../../components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import TypewriterEffect from '../../components/TypewriterEffect';

const languagesList = [
  { name: 'Spanish', src: '/images/mexico_flag.png' },
  { name: 'Japanese', src: '/images/icon_flag-ja.svg' },
  { name: 'Portuguese', src: '/images/icon_flag-pt.svg' },
  { name: 'French', src: '/images/icon_flag-fr.svg' },
  { name: 'German', src: '/images/icon_flag-de.svg' },
  { name: 'Italian', src: '/images/icon_flag-it.svg' },
  { name: 'Mandarin', src: '/images/icon_flag-zh.svg' },
  { name: 'Arabic', src: '/images/icon_flag-ar.svg' },
  { name: 'Cantonese', src: '/images/icon_flag-hk.png' },
  { name: 'Korean', src: '/images/icon_flag-ko.svg' },
  { name: 'Russian', src: '/images/icon_flag-ru.svg' },
  { name: 'Tagalog', src: '/images/icon_flag_pp.png' },
  { name: 'Greek', src: '/images/icon_flag_gr.png' },
  { name: 'Hindi', src: '/images/icon_flag_in.png' },
];

function Home() {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [showTippy, setShowTippy] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [itemsPerView, setItemsPerView] = useState(6);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.1 }
    );

    const aboutSection = containerRef.current;
    if (aboutSection) {
      observer.observe(aboutSection);
    }

    return () => {
      if (aboutSection) {
        observer.unobserve(aboutSection);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setItemsPerView(mobile ? 3 : 6); // Show 3 items on mobile, 6 on desktop
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.body.classList.add('home-page');
    return () => {
      document.body.classList.remove('home-page');
    };
  }, []);

  const toggleLanguageSelection = (language) => {
    setSelectedLanguages((prevState) =>
      prevState.includes(language)
        ? prevState.filter((l) => l !== language)
        : [...prevState, language]
    );
  };

  const handleGetStarted = () => {
    navigate('/selectlanguages');
  };

  const handleLanguageGetStarted = () => {
    if (selectedLanguages.length === 0) {
      setShowTippy(true);
      setTimeout(() => setShowTippy(false), 3000);
    } else {
      navigate('/register', { state: { selectedLanguages } });
    }
  };

  const handleNextLanguages = () => {
    const moveBy = isMobile ? 3 : 6; // Move 3 at a time on mobile
    const maxIndex = languagesList.length - itemsPerView;
    setCurrentIndex(prev => Math.min(prev + moveBy, maxIndex));
  };

  const handlePreviousLanguages = () => {
    const moveBy = isMobile ? 3 : 6; // Move 3 at a time on mobile
    setCurrentIndex(prev => Math.max(prev - moveBy, 0));
  };

  return (
    <div className="home-page">
      <div className="home-container">
        <div className="home-header-section">
          <div className="home-header-text">
            <h1>{t('home.title')}</h1>
            <div className="typewriter-container">
              <TypewriterEffect />
            </div>
            <div className="home-get-started-container">
              <button className="getstartedwithlanguage-button" onClick={handleGetStarted}>
                {t('home.getStarted')}
              </button>
            </div>
          </div>
          <div className="home-header-image">
            <img src="/images/logo.png" alt="Language learning" />
          </div>
        </div>

        <div className="home-language-section">
          <h2>{t('home.wantToLearn')}</h2>
          <div className="home-languages-navigation">
            <button onClick={handlePreviousLanguages} disabled={currentIndex === 0}>
              {"<"}
            </button>
            <div className="home-languages-container-wrapper">
              <div
                className="home-languages-container"
                ref={containerRef}
                style={{
                  transform: `translateX(-${currentIndex * (isMobile ? 33.33 : 16.67)}%)`,
                }}
              >
                {languagesList.map((language) => (
                  <div
                    key={language.name}
                    className={`home-language-item ${
                      selectedLanguages.includes(language.name) ? 'selected' : ''
                    }`}
                    onClick={() => toggleLanguageSelection(language.name)}
                  >
                    <img src={language.src} alt={language.name} />
                    <p>{language.name}</p>
                    {selectedLanguages.includes(language.name) && (
                      <span className="home-checkmark">✔️</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={handleNextLanguages}
              disabled={currentIndex + itemsPerView >= languagesList.length}
            >
              {">"}
            </button>
          </div>

          <div className="home-get-started-container-languages-selected">
            <Tippy content="Please select your languages above!" visible={showTippy} animation="scale" arrow={true}>
              <button className="home-get-started-button-2" onClick={handleLanguageGetStarted}>
                {t('home.getStartedWithLanguages')}
              </button>
            </Tippy>
          </div>
        </div>

        <div className="home-about-section" ref={containerRef}>
          <h2 className="home-about-header">{t('home.whyLearn')}</h2>

          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon">
                <img src="/images/home-page-video-chat.png" alt={t('home.features.videoChatStatus')} />
              </div>
              <h3>{t('home.realConversations.title')}</h3>
              <p>{t('home.realConversations.description')}</p>
            </div>

            <div className="feature-card">
              <div className="feature-icon">
                <img src="/images/ai-convo.webp" alt={t('languagePage.conversation.title')} />
              </div>
              <h3>{t('home.chatUsers.title')}</h3>
              <p>{t('home.chatUsers.description')}</p>
            </div>

            <div className="feature-card">
              <div className="feature-icon">
                <img src="/images/chat-functionality.png" alt={t('chat.rooms.title')} />
              </div>
              <h3>{t('home.features.community.title')}</h3>
              <p>{t('home.features.community.description', { defaultValue: 'Connect with learners and native speakers from around the world. Make friends while learning languages.' })}</p>
            </div>

            <div className="feature-card">
              <div className="feature-icon">
                <img src="/images/home-page-game-badges.png" alt={t('home.features.achievementBadges')} />
              </div>
              <h3>{t('home.stayMotivated.title')}</h3>
              <p>{t('home.stayMotivated.description')}</p>
            </div>
          </div>

          <div className="stats-section">
            <div className="stat-item">
              <span className="stat-number">14</span>
              <span className="stat-label">{t('home.features.availableLanguages')}</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">10+</span>
              <span className="stat-label">{t('home.features.activeUsers.title')}</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">24/7</span>
              <span className="stat-label">{t('common.support')}</span>
            </div>
          </div>
        </div>

        <div className="home-container">
          <div className="home-info-section">
            <div className="info-content">
              <div className="info-social">
                <h3>{t('home.connectWithUs')}</h3>
                <div className="social-icons">
                  <a href="mailto:learnwithspeakeasy@gmail.com" target="_blank" rel="noopener noreferrer">
                    <FaEnvelope />
                  </a>
                  <a href="https://instagram.com/speakeasy" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                  <a href="https://twitter.com/speakeasy" target="_blank" rel="noopener noreferrer">
                    <FaTwitter />
                  </a>
                  <a href="https://www.linkedin.com/in/mattheos-drivas-803188276/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

  
      </div>

      <LanguageSelector />
    </div>
  );
}

export default Home;
