// Track page views
export const pageView = (path) => {
  if (window.gtag) {
    window.gtag('config', 'G-WWXBLRWR6T', {
      page_path: path
    });
  }
};

// Track events (for custom interactions)
export const event = ({ action, category, label, value }) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  }
}; 