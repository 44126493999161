import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../../contexts/AuthContext';
import './Pro.css';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const PRICE_ID = process.env.REACT_APP_STRIPE_PRICE_ID;
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Pro = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCheckout = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const stripe = await stripePromise;
      
      const response = await fetch(`${apiUrl}/subscription/create-checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user.id,
          priceId: PRICE_ID
        }),
        credentials: 'include'
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || 'Failed to create checkout session');

      const result = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (result.error) throw new Error(result.error.message);

    } catch (err) {
      setError(err.message);
      console.error('Checkout error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pro-page">
      <div className="pro-hero">
        <div className="hero-content">
          <h1>{t('proModal.title.prefix')} <span className="highlight">{t('proModal.title.highlight')}</span></h1>
          <div className="price-tag">
            <span className="amount">{t('proModal.pricing.amount')}</span>
            <span className="period">{t('proModal.pricing.period')}</span>
          </div>
          <button 
            className={`checkout-button ${isLoading ? 'loading' : ''}`}
            onClick={handleCheckout}
            disabled={isLoading}
          >
            {isLoading ? t('common.loading') : t('common.upgradeNow')}
          </button>
          {error && <div className="error-message">{error}</div>}
        </div>
        <div className="hero-image">
          <img 
            src={`${process.env.PUBLIC_URL}/images/home-page-video-chat.png`} 
            alt={t('proModal.imageAlt')} 
          />
        </div>
      </div>

      <div className="features-grid">
        <div className="feature-card">
          <img 
            src={`${process.env.PUBLIC_URL}/images/home-page-video-chat.png`}
            alt={t('proModal.features.videoChat.title')} 
          />
          <h3>{t('proModal.features.videoChat.title')}</h3>
          <p>{t('proModal.features.videoChat.tooltip')}</p>
        </div>

        <div className="feature-card">
          <img 
            src={`${process.env.PUBLIC_URL}/images/travel-icon.webp`}
            alt={t('proModal.features.premium.title')} 
          />
          <h3>{t('proModal.features.premium.title')}</h3>
          <p>{t('proModal.features.premium.tooltip')}</p>
        </div>

        <div className="feature-card">
          <img 
            src={`${process.env.PUBLIC_URL}/images/book-writing.svg`} 
            alt={t('proModal.features.ai.title')} 
          />
          <h3>{t('proModal.features.ai.title')}</h3>
          <p>{t('proModal.features.ai.tooltip')}</p>
        </div>

        <div className="feature-card">
          <img 
            src={`${process.env.PUBLIC_URL}/images/book-writing.webp`} 
            alt={t('proModal.features.wordpacks.title')} 
          />
          <h3>{t('proModal.features.wordpacks.title')}</h3>
          <p>{t('proModal.features.wordpacks.tooltip')}</p>
        </div>
      </div>
    </div>
  );
};

export default Pro; 