import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Line, Bar, Radar } from 'react-chartjs-2';
import Select from 'react-select';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import './ProgressDashboard.css';
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import SpeakeasyProModal from '../SpeakeasyProModal/SpeakeasyProModal';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);

// Helper functions
const getLanguageEmoji = (language) => {
  const emojiMap = {
    'Spanish': '🇪🇸',
    'French': '🇫🇷',
    'German': '🇩🇪',
    'Italian': '🇮🇹',
    'Portuguese': '🇵🇹',
    'Japanese': '🇯🇵',
    'Korean': '🇰🇷',
    'Mandarin': '🇨🇳',
    'Cantonese': '🇭🇰',
    'Russian': '🇷🇺',
    'Arabic': '🇸🇦',
    'Greek': '🇬🇷'
  };
  return emojiMap[language] || '🌐';
};

const formatDate = (dateString) => {
  const date = new Date(dateString.replace(' ', 'T') + 'Z');
  const now = new Date();
  const diffInMinutes = Math.round((now - date) / (1000 * 60));
  
  if (diffInMinutes < 0) {
    return 'just now';
  }
  
  if (diffInMinutes < 60) {
    return diffInMinutes <= 1 ? 'just now' : `${diffInMinutes}m ago`;
  }
  
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  }
  
  if (diffInHours < 168) {
    return new Intl.DateTimeFormat('en', {
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(date);
  }
  
  return new Intl.DateTimeFormat('en', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(date);
};

const getExactTimestamp = (dateString) => {
  const date = new Date(dateString.replace(' ', 'T') + 'Z');
  return new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  }).format(date);
};

const getActivityIcon = (type) => {
  const gameIcons = {
    'vocabularyRace': '🎯',
    'storyChain': '📝',
    'videoCall': '🎥',
    'wordpack': '📚'
  };
  return gameIcons[type] || '🎮';
};

const formatDuration = (duration) => {
  if (!duration) return '0m';
  if (duration < 60) {
    return `${duration}m`;
  }
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours}h ${minutes}m`;
};

// Add apiUrl constant
const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Add custom styles for the Select component
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? 'var(--primary-color)' : 'transparent',
    '&:hover': {
      backgroundColor: state.isSelected ? 'var(--primary-color)' : 'var(--hover-color)'
    }
  }),
  control: (provided) => ({
    ...provided,
    minWidth: '200px',
    border: '1px solid var(--border-color)',
    borderRadius: '8px',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid var(--primary-color)'
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  })
};

// Add chart options configurations
const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: 'rgba(0, 0, 0, 0.1)'
      }
    },
    x: {
      grid: {
        display: false
      }
    }
  }
};

const barOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      max: 100,
      ticks: {
        stepSize: 20,
        callback: function(value) {
          const levels = ['', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
          return levels[Math.floor(value/20)] || '';
        }
      },
      grid: {
        color: 'rgba(0, 0, 0, 0.1)'
      }
    },
    x: {
      grid: {
        display: false
      }
    }
  }
};

const calculateSkillScores = (stats) => {
  if (!stats) return Array(5).fill(0);

  const vocabulary = Math.min(
    ((stats.wordsLearned || 0) * 0.6 + 
     (stats.games?.vocabularyRace || 0) * 0.4) * 5,
    100
  );

  const grammar = Math.min(
    ((stats.games?.storyChain || 0) * 0.7 + 
     (stats.wordpacks || 0) * 0.3) * 5,
    100
  );

  const conversation = Math.min(
    ((stats.videoCalls?.count || 0) * 0.6 + 
     (stats.videoCalls?.duration || 0) / 60 * 0.4) * 2,
    100
  );

  const pronunciation = Math.min(
    ((stats.videoCalls?.count || 0) * 0.4 + 
     (stats.games?.vocabularyRace || 0) * 0.6) * 4,
    100
  );

  const comprehension = Math.min(
    ((stats.totalActivities || 0) * 0.3 + 
     (stats.videoCalls?.duration || 0) / 60 * 0.4 +
     (stats.wordsLearned || 0) * 0.3) * 3,
    100
  );

  return [vocabulary, grammar, conversation, pronunciation, comprehension];
};

const radarOptions = {
  responsive: true,
  scales: {
    r: {
      beginAtZero: true,
      max: 100,
      ticks: {
        display: false
      },
      grid: {
        color: 'rgba(0, 0, 0, 0.1)'
      },
      angleLines: {
        color: 'rgba(0, 0, 0, 0.1)'
      },
      pointLabels: {
        font: {
          size: 12
        }
      }
    }
  },
  plugins: {
    tooltip: {
      enabled: false
    },
    legend: {
      display: false
    }
  }
};

const ProgressDashboard = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState('all');
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showProModal, setShowProModal] = useState(!user?.is_pro);

  const handleCloseProModal = () => {
    setShowProModal(false);
    navigate('/dashboard'); // Redirect to dashboard when they close the modal
  };

  // Fetch data only once on component mount
  useEffect(() => {
    const fetchProgressData = async () => {
      if (!user || !user.id) return;
      
      try {
        setIsLoading(true);
        const response = await fetch(`${apiUrl}/progress/stats?user_id=${user.id}`);
        const responseData = await response.json();
        
        if (responseData.success) {
          setStats(responseData.data);
          setError(null);
        } else {
          setError(responseData.message || 'Failed to fetch progress data');
        }
      } catch (err) {
        console.error('Error fetching progress data:', err);
        setError(t('progress.errors.fetchFailed'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchProgressData();
  }, [user, t]); // Remove selectedLanguage dependency

  // Get current language stats based on selection
  const currentLanguageStats = selectedLanguage === 'all' 
    ? stats?.languages?.['All Languages']
    : stats?.languages?.[selectedLanguage];

  // Language options with emojis (moved outside useEffect)
  const languageOptions = [
    { value: 'all', label: (
      <div className="language-option">
        <span className="language-emoji">🌎</span>
        <span>{t('common.allLanguages')}</span>
      </div>
    )},
    ...(stats?.languages ? Object.entries(stats.languages)
      .filter(([lang]) => 
        lang !== 'All Languages' && 
        stats.languages[lang].totalActivities > 0
      )
      .map(([lang]) => ({
        value: lang,
        label: (
          <div className="language-option">
            <span className="language-emoji">{getLanguageEmoji(lang)}</span>
            <span>{lang}</span>
          </div>
        )
      })) : [])
  ];

  // Only show loading on initial fetch
  if (isLoading && !stats) {
    return <div className="progress-analytics loading">{t('common.loading')}</div>;
  }

  if (error) {
    return (
      <div className="progress-analytics error">
        <p className="error-message">{error}</p>
        <button onClick={() => window.location.reload()}>{t('common.retry')}</button>
      </div>
    );
  }

  if (!user?.is_pro) {
    return (
      <div className="progress-pro-overlay">
        <div className="progress-pro-content">
          <h2>{t('progress.proOnly.title')}</h2>
          <p>{t('progress.proOnly.description')}</p>
          <button 
            className="progress-upgrade-button"
            onClick={() => setShowProModal(true)}
          >
            {t('common.upgradeNow')}
          </button>
        </div>
        <SpeakeasyProModal
          isOpen={showProModal}
          onRequestClose={handleCloseProModal}
        />
      </div>
    );
  }

  // Prepare chart data
  const weeklyActivityData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [{
      label: t('progress.charts.activity'),
      data: Array(7).fill(0), // You'll need to add weekly activity data from backend
      fill: true,
      borderColor: 'rgb(75, 192, 192)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      tension: 0.4
    }]
  };

  const languageProgressData = {
    labels: Object.keys(stats?.languages || {}).filter(lang => lang !== 'All Languages'),
    datasets: [{
      data: Object.entries(stats?.languages || {})
        .filter(([lang]) => lang !== 'All Languages')
        .map(([_, data]) => {
          const levelMap = { 'A1': 20, 'A2': 40, 'B1': 60, 'B2': 80, 'C1': 90, 'C2': 100 };
          return levelMap[data.level] || 0;
        }),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgb(75, 192, 192)',
      borderWidth: 1
    }]
  };

  // Add skillRadarData configuration
  const skillRadarData = {
    labels: [
      t('progress.skills.vocabulary'),
      t('progress.skills.grammar'),
      t('progress.skills.conversation'),
      t('progress.skills.pronunciation'),
      t('progress.skills.comprehension')
    ],
    datasets: [{
      label: t('progress.skills.strength'),
      data: calculateSkillScores(currentLanguageStats),
      fill: true,
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgb(75, 192, 192)',
      pointBackgroundColor: 'rgb(75, 192, 192)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(75, 192, 192)'
    }]
  };

  return (
    <div className="progress-analytics">
      <div className="progress-header">
        <h1>{t('progress.title')}</h1>
        <Select
          options={languageOptions}
          value={languageOptions.find(opt => opt.value === selectedLanguage)}
          onChange={(option) => setSelectedLanguage(option.value)}
          styles={customSelectStyles}
          className="language-select"
          classNamePrefix="language-select"
          isSearchable={false}
        />
      </div>
      
      <div className="progress-stats-grid">
        <div className="progress-stat-card">
          <h3>{t('progress.stats.vocabularyRace')}</h3>
          <p>{currentLanguageStats?.games?.vocabularyRace || 0}</p>
        </div>
        <div className="progress-stat-card">
          <h3>{t('progress.stats.storyChain')}</h3>
          <p>{currentLanguageStats?.games?.storyChain || 0}</p>
        </div>
        <div className="progress-stat-card">
          <h3>{t('progress.stats.wordpacksOpened')}</h3>
          <p>{currentLanguageStats?.wordpacks || 0}</p>
        </div>
        <div className="progress-stat-card">
          <h3>{t('progress.stats.videoCalls')}</h3>
          <p>{currentLanguageStats?.videoCalls?.count || 0}</p>
        </div>
      </div>

      <div className="progress-charts-row">
        <div className="progress-chart-container">
          <h3>{t('progress.charts.weeklyActivity')}</h3>
          <Line data={weeklyActivityData} options={chartOptions} />
        </div>
        <div className="progress-chart-container">
          <h3>{t('progress.charts.languageProgress')}</h3>
          <Bar data={languageProgressData} options={barOptions} />
        </div>
      </div>

      <div className="progress-charts-row">
        <div className="progress-chart-container">
          <h3>{t('progress.charts.skillBreakdown')}</h3>
          <Radar data={skillRadarData} options={radarOptions} />
        </div>
        <div className="progress-chart-container">
          <div className="recent-activity">
            <h3>{t('progress.recentActivity.title')}</h3>
            <div className="activity-list">
              {(stats?.recentActivity || []).map((activity, index) => (
                <div key={index} className="activity-item">
                  <div className="activity-icon">
                    {getActivityIcon(activity.type)}
                  </div>
                  <div className="activity-details">
                    <p className="activity-title">
                      {activity.type === 'videoCall' 
                        ? `${t('progress.activity.chatWith')} ${activity.partner}`
                        : activity.name
                      }
                    </p>
                    <p className="activity-subtitle" title={getExactTimestamp(activity.date)}>
                      {activity.language} • {formatDate(activity.date)}
                    </p>
                  </div>
                  <div className="activity-meta">
                    {activity.type === 'videoCall' 
                      ? formatDuration(activity.duration)
                      : activity.score 
                        ? `${activity.score}%`
                        : null
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressDashboard; 