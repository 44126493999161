import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import Globe from 'react-globe.gl';
import { useTranslation } from 'react-i18next';
import './Globe.css';

// Country information mapping
const countryInfo: Record<string, {
  coordinates: { lat: number; lng: number };
  flag: string;
}> = {
  it: {
    coordinates: { lat: 41.87, lng: 12.56 },
    flag: "🇮🇹",
  },
  jp: {
    coordinates: { lat: 36.20, lng: 138.25 },
    flag: "🇯🇵",
  },
  fr: {
    coordinates: { lat: 46.22, lng: 2.21 },
    flag: "🇫🇷",
  },
  es: {
    coordinates: { lat: 40.46, lng: -3.74 },
    flag: "🇪🇸",
  },
  de: {
    coordinates: { lat: 51.16, lng: 10.45 },
    flag: "🇩🇪",
  },
  kr: {
    coordinates: { lat: 35.90, lng: 127.76 },
    flag: "🇰🇷",
  },
  cn: {
    coordinates: { lat: 35.86, lng: 104.19 },
    flag: "🇨🇳",
  },
  pt: {
    coordinates: { lat: 39.39, lng: -8.22 },
    flag: "🇵🇹",
  },
  br: {
    coordinates: { lat: -14.23, lng: -51.92 },
    flag: "🇧🇷",
  },
  mx: {
    coordinates: { lat: 23.63, lng: -102.55 },
    flag: "🇲🇽",
  },
  th: {
    coordinates: { lat: 15.87, lng: 100.99 },
    flag: "🇹🇭",
  },
  vn: {
    coordinates: { lat: 14.05, lng: 108.27 },
    flag: "🇻🇳",
  }
};

interface CountryOption {
  value: string;
  label: string;
}

interface Props {
  onSelectCountry: (countryId: string) => void;
  countryOptions: CountryOption[];
}

export interface GlobeRef {
  triggerAnimation: () => void;
}

interface CustomPoint {
  id: string;
  name: string;
  lat: number;
  lng: number;
  flag: string;
}

export const GlobeComponent = forwardRef<GlobeRef, Props>((props, ref) => {
  const { onSelectCountry, countryOptions } = props;
  const { t } = useTranslation();
  const globeEl = React.useRef<any>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState<CustomPoint | null>(null);

  const triggerAnimation = () => {
    if (containerRef.current) {
      containerRef.current.classList.remove('animate-in');
      void containerRef.current.offsetWidth;
      containerRef.current.classList.add('animate-in');
      setTimeout(() => {
        containerRef.current?.classList.remove('animate-in');
      }, 1000);
    }
  };

  useImperativeHandle(ref, () => ({
    triggerAnimation
  }));

  const handleCountrySelect = (point: any) => {
    if (point && point.id) {
      const country = countryInfo[point.id];
      if (country) {
        setShowPopup(false);
        onSelectCountry(point.id);
        setSelectedPoint(point);
        
        globeEl.current.pointOfView({
          lat: country.coordinates.lat,
          lng: country.coordinates.lng,
          altitude: 1.5
        }, 1000);

        setTimeout(() => {
          setShowPopup(true);
        }, 1000);

        triggerAnimation();
      }
    }
  };

  const resetView = () => {
    setShowPopup(false);
    globeEl.current.pointOfView({
      lat: 25,
      lng: 0,
      altitude: 2.5
    }, 1000);
  };

  useEffect(() => {
    if (globeEl.current) {
      const controls = globeEl.current.controls();
      controls.autoRotate = true;
      controls.autoRotateSpeed = 1.3;
      controls.enableZoom = true;
      controls.enablePan = true;
      controls.minDistance = 200;
      controls.maxDistance = 800;
      
      controls.minPolarAngle = Math.PI / 3;
      controls.maxPolarAngle = Math.PI * 2/3;
      
      globeEl.current.pointOfView({
        lat: 25,
        lng: 0,
        altitude: 2.5
      });
    }
  }, []);

  return (
    <div ref={containerRef} className="globe-container">
      <Globe
        ref={globeEl}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
        bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
        backgroundColor="#0f0e17"
        atmosphereColor="#6c63ff"
        atmosphereAltitude={0.15}
        pointsData={countryOptions
          .filter(option => option.value !== 'all')
          .map(option => ({
            id: option.value,
            name: t(`globe.countries.${option.value}.name`),
            lat: countryInfo[option.value].coordinates.lat,
            lng: countryInfo[option.value].coordinates.lng,
            color: '#ffffff',
            radius: 1.5
          }))}
        pointLat="lat"
        pointLng="lng"
        pointColor="color"
        pointAltitude={0}
        pointRadius="radius"
        pointsMerge={false}
        pointResolution={24}
        onPointClick={(point: any) => {
          if (point) {
            handleCountrySelect({
              id: point.id,
              name: point.name,
              lat: point.lat,
              lng: point.lng,
              flag: countryInfo[point.id].flag
            });
          }
        }}
        onPointHover={(point: any) => {
          document.body.style.cursor = point ? 'pointer' : 'default';
        }}
        width={700}
        height={700}
      />

      {showPopup && selectedPoint && (
        <div className="country-popup">
          <button className="close-popup" onClick={resetView}>{t('globe.close')}</button>
          <h2>{selectedPoint.name}</h2>
          <div className="country-info">
            <p><strong>{t('globe.capital')}:</strong> {t(`globe.countries.${selectedPoint.id}.capital`)}</p>
            <p><strong>{t('globe.population')}:</strong> {t(`globe.countries.${selectedPoint.id}.population`)}</p>
            <p><strong>{t('globe.languages')}:</strong> {t(`globe.countries.${selectedPoint.id}.languages`)}</p>
            <p><strong>{t('globe.funFact')}:</strong> {t(`globe.countries.${selectedPoint.id}.funFact`)}</p>
          </div>
        </div>
      )}
    </div>
  );
}); 