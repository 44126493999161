import React from 'react';
import { useTranslation } from 'react-i18next';
import './TravelVideoGrid.css';

interface Video {
  id: string;
  thumbnail: string;
  url: string;
}

interface TravelVideoGridProps {
  selectedCountry: string;
  showEmptyMessage?: boolean;
}

interface CountryOption {
  value: string;
  label: string;
}

const countryOptions: CountryOption[] = [
  { value: 'all', label: '🌎 All Locations' },
  { value: 'it', label: '🇮🇹 Italy' },
  { value: 'jp', label: '🇯🇵 Japan' },
  // ... rest of your country options
];

const countryVideos: Record<string, Video[]> = {
  all: [
    {
      id: 'vn0r6gYyQ3g',
      thumbnail: 'https://i.ytimg.com/vi/vn0r6gYyQ3g/maxresdefault.jpg',
      url: 'https://www.youtube.com/watch?v=vn0r6gYyQ3g'
    },
    {
      id: 'Gu7JDzo43Zs',
      thumbnail: 'https://i.ytimg.com/vi/Gu7JDzo43Zs/maxresdefault.jpg',
      url: 'https://www.youtube.com/watch?v=Gu7JDzo43Zs'
    },
    {
      id: 'J3jufq8b7ms',
      thumbnail: 'https://i.ytimg.com/vi/J3jufq8b7ms/maxresdefault.jpg',
      url: 'https://www.youtube.com/watch?v=J3jufq8b7ms'
    }
  ],
  jp: [
   
  ],
  kr: [
    
  ],
  fr: [
    
  ],
  it: [],
  es: [
    {
        id: 'Gu7JDzo43Zs',
        thumbnail: 'https://i.ytimg.com/vi/Gu7JDzo43Zs/maxresdefault.jpg',
        url: 'https://www.youtube.com/watch?v=Gu7JDzo43Zs'
      },
  ],
  de: [],
  cn: [],
  pt: [],
  br: [
    {
        id: 'J3jufq8b7ms',
        thumbnail: 'https://i.ytimg.com/vi/J3jufq8b7ms/maxresdefault.jpg',
        url: 'https://www.youtube.com/watch?v=J3jufq8b7ms'
      }
  ],
  mx: [
    {
      id: 'vn0r6gYyQ3g',
      thumbnail: 'https://i.ytimg.com/vi/vn0r6gYyQ3g/maxresdefault.jpg',
      url: 'https://www.youtube.com/watch?v=vn0r6gYyQ3g'
    }
  ],
  th: [],
  vn: []
};

export const TravelVideoGrid: React.FC<TravelVideoGridProps> = ({ selectedCountry, showEmptyMessage = false }) => {
  const { t } = useTranslation();
  const videos = countryVideos[selectedCountry] || [];

  if (videos.length === 0) {
    if (!showEmptyMessage) return null;
    
    return (
      <div className="no-content-message">
        <div className="message-content">
          <h3>{t('Travel.videos.empty.title')}</h3>
          <p>{t('Travel.videos.empty.description', {
            location: selectedCountry === 'all' 
              ? t('Travel.videos.empty.allDestinations')
              : countryOptions.find(opt => opt.value === selectedCountry)?.label.split(' ')[1]
          })}</p>
          <p>{t('Travel.videos.empty.checkBack')}</p>
        </div>
      </div>
    );
  }

  return (
    <section className="travel-videos-section">
      <div className="section-header">
        <h2>{t('Travel.videos.title')}</h2>
      </div>
      <div className="video-grid">
        {videos.map((video) => (
          <a 
            key={video.id} 
            href={video.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="video-card"
          >
            <div className="video-thumbnail">
              <img src={video.thumbnail} alt={t('Travel.videos.thumbnailAlt')} />
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};