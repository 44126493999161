import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { firestore } from "../../config/firebase";
import { AuthContext } from "../../contexts/AuthContext";
import './Discover.css';
import './Leaderboard.css';
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import './SpotifySection.css'; // We'll create this new CSS file
import './VideoChatSection.css'; // We'll create this new CSS file
import VideoChatSection from './VideoChatSection';
import { useTranslation } from 'react-i18next';
import FeedbackForm from '../../components/FeedbackForm';
import TravelSection from '../../components/TravelSection/TravelSection';
import AIConversationSection from './AIConversationSection';
import Modal from 'react-modal';

const languageImages = {
  Spanish: '/images/mexico_flag.png',
  Japanese: '/images/icon_flag-ja.svg',
  Portuguese: '/images/icon_flag-pt.svg',
  French: '/images/icon_flag-fr.svg',
  German: '/images/icon_flag-de.svg',
  Italian: '/images/icon_flag-it.svg',
  Mandarin: '/images/icon_flag-zh.svg',
  Arabic: '/images/icon_flag-ar.svg',
  Cantonese: '/images/icon_flag-hk.png',
  Korean: '/images/icon_flag-ko.svg',
  Russian: '/images/icon_flag-ru.svg',
  Tagalog: '/images/icon_flag_pp.png',
  Greek: '/images/icon_flag_gr.png',
  Hindi: '/images/icon_flag_in.png',
};

const languageChats = [
  'spanish', 'japanese', 'portuguese', 'french', 'german', 'italian',
  'mandarin', 'arabic', 'cantonese', 'korean', 'russian', 'tagalog',
  'greek', 'hindi',
];

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const languageFlags = {
  Spanish: '/images/mexico_flag.png',
  Japanese: '/images/icon_flag-ja.svg',
  Portuguese: '/images/icon_flag-pt.svg',
  French: '/images/icon_flag-fr.svg',
  German: '/images/icon_flag-de.svg',
  Italian: '/images/icon_flag-it.svg',
  Mandarin: '/images/icon_flag-zh.svg',
  Arabic: '/images/icon_flag-ar.svg',
  Cantonese: '/images/icon_flag-hk.png',
  Korean: '/images/icon_flag-ko.svg',
  Russian: '/images/icon_flag-ru.svg',
  Tagalog: '/images/icon_flag_pp.png',
  Greek: '/images/icon_flag_gr.png',
  Hindi: '/images/icon_flag_in.png',
};

Modal.setAppElement('#root');

const Discover = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [profiles, setProfiles] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('Spanish');
  const [sortCriteria, setSortCriteria] = useState('vocabRace');
  const [selectedLevel, setSelectedLevel] = useState('easy');
  const [vocabRaceData, setVocabRaceData] = useState([]);
  const [vocabRaceGamesData, setVocabRaceGamesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTourActive, setIsTourActive] = useState(false);
  const [videoCallsData, setVideoCallsData] = useState([]);
  const { t } = useTranslation();
  const [userLanguages, setUserLanguages] = useState([]);
  const [languageModalIsOpen, setLanguageModalIsOpen] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedVideoLanguage, setSelectedVideoLanguage] = useState('trending');

  const videosByLanguage = {
    trending: [
      { id: '9udK_aFkrg8', language: 'trending' },
      { id: 'lwkjxXo9BSA', language: 'trending' },
      { id: 'i_nMQNUmGkM', language: 'trending' },
      { id: 'hpsgQkD27BA', language: 'trending' },
      { id: 'p_CvFgP4y-w', language: 'trending' },

    ],
    spanish: [
      { id: 'hpsgQkD27BA', language: 'spanish' },
    ],
    japanese: [
      { id: 'lwkjxXo9BSA', language: 'japanese' },
    ],
    mandarin: [
      { id: '9udK_aFkrg8', language: 'mandarin' },
    ],
    // Add more languages as needed
  };

  const currentVideos = videosByLanguage[selectedVideoLanguage] || videosByLanguage.trending;
  const videoIds = currentVideos.map(video => video.id);
  const videoThumbnails = videoIds.map((id) => `https://img.youtube.com/vi/${id}/maxresdefault.jpg`);

  const fetchProfiles = useCallback(async (search = '') => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/public_profiles?search=${encodeURIComponent(search)}`);
      const data = await response.json();
      if (data.success) {
        setProfiles(data.profiles.map(profile => ({
          ...profile,
          is_verified: Boolean(profile.is_verified)
        })));
      } else {
        console.error('Failed to fetch profiles:', data.message);
        setProfiles([]);
      }
    } catch (error) {
      console.error('Error fetching profiles:', error);
      setProfiles([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchLeaderboardData = useCallback(async () => {
    if (sortCriteria === 'chatMessages') {
      try {
        const users = {};
        for (const language of languageChats) {
          const q = query(collection(firestore, 'chats', language, 'messages'), orderBy('timestamp', 'asc'));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const { user_id, display_name, profile_picture } = data;
            if (!users[user_id]) {
              users[user_id] = {
                userId: user_id,
                displayName: display_name,
                profilePicture: profile_picture,
                totalMessages: 0,
              };
            }
            users[user_id].totalMessages += 1;
          });
        }
        const leaderboard = Object.values(users)
          .sort((a, b) => b.totalMessages - a.totalMessages)
          .slice(0, 10);
        setLeaderboardData(leaderboard);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    } else if (sortCriteria === 'videoCalls') {
      try {
        const response = await fetch(`${apiUrl}/video_call/leaderboard`);
        const data = await response.json();
        if (data.success) {
          setVideoCallsData(data.leaderboard);
        } else {
          console.error('Failed to fetch video calls leaderboard:', data.message);
        }
      } catch (error) {
        console.error('Error fetching video calls leaderboard:', error);
      }
    } else if (sortCriteria === 'vocabRace') {
      try {
        const response = await fetch(`${apiUrl}/average_time/${selectedLanguage}/${selectedLevel}`);
        const data = await response.json();
        if (data.success) {
          setVocabRaceData(data.leaderboard);
        } else {
          console.error('Failed to fetch Vocab Race leaderboard data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching Vocab Race leaderboard data:', error);
      }
    } else if (sortCriteria === 'vocabRaceGames') {
      try {
        const response = await fetch(`${apiUrl}/total_games/${selectedLanguage}/${selectedLevel}`);
        const data = await response.json();
        if (data.success) {
          setVocabRaceGamesData(data.leaderboard);
        } else {
          console.error('Failed to fetch Vocab Race Games leaderboard data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching Vocab Race Games leaderboard data:', error);
      }
    } else if (sortCriteria === 'storyChainGames') {
      try {
        const response = await fetch(`${apiUrl}/games_played_leaderboard/Story Chain/${selectedLanguage}`);
        const data = await response.json();
        if (data.success) {
          setLeaderboardData(data.leaderboard);
        } else {
          console.error('Failed to fetch Story Chain Games leaderboard data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching Story Chain Games leaderboard data:', error);
      }
    }
  }, [sortCriteria, selectedLevel, selectedLanguage]);

  const fetchUserLanguages = useCallback(async () => {
    if (!user?.id) return;
    
    try {
      const response = await fetch(
        `${apiUrl}/user/interests?user_id=${user.id}`,
        {
          method: "GET",
          headers: { 
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      
      if (data.success) {
        setUserLanguages(data.interests);
      } else {
        console.error("Failed to fetch interests:", data.message);
      }
    } catch (error) {
      console.error("Error fetching user languages:", error);
    }
  }, [user?.id]);

  useEffect(() => {
    fetchProfiles(); // Fetch initial profiles without a search term

    const intervalId = setInterval(() => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoThumbnails.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [fetchProfiles, videoThumbnails.length]);

  // Separate useEffect for fetching leaderboard data when criteria change
  useEffect(() => {
    fetchLeaderboardData();
  }, [fetchLeaderboardData]);

  useEffect(() => {
    fetchUserLanguages();
  }, [fetchUserLanguages]);

  const handleDiscoverTour = useCallback(() => {
    setIsTourActive(true);

    // Create an overlay
    const overlay = document.createElement('div');
    overlay.className = 'tour-overlay';
    document.body.appendChild(overlay);

    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: {
          enabled: true
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        classes: 'custom-tour-class',
      },
      useModalOverlay: true,
    });

    tour.addStep({
      title: t('discover.tour.welcome.title'),
      text: t('discover.tour.welcome.text'),
      attachTo: {
        element: '.navbar',
        on: 'bottom'
      },
      buttons: [
        {
          text: t('discover.tour.buttons.next'),
          action: tour.next
        }
      ]
    });

    tour.addStep({
      title: t('discover.tour.videos.title'),
      text: t('discover.tour.videos.text'),
      attachTo: {
        element: '.discover-video-wrapper',
        on: 'bottom'
      },
      buttons: [
        {
          text: t('discover.tour.buttons.back'),
          action: tour.back
        },
        {
          text: t('discover.tour.buttons.next'),
          action: tour.next
        }
      ]
    });

    tour.addStep({
      title: t('discover.tour.picks.title'),
      text: t('discover.tour.picks.text'),
      attachTo: {
        element: '.our-picks-options',
        on: 'top'
      },
      buttons: [
        {
          text: t('discover.tour.buttons.back'),
          action: tour.back
        },
        {
          text: t('discover.tour.buttons.next'),
          action: tour.next
        }
      ]
    });

    tour.addStep({
      title: t('discover.tour.profiles.title'),
      text: t('discover.tour.profiles.text'),
      attachTo: {
        element: '.discover-profiles-grid',
        on: 'top'
      },
      buttons: [
        {
          text: t('discover.tour.buttons.back'),
          action: tour.back
        },
        {
          text: t('discover.tour.buttons.next'),
          action: tour.next
        }
      ]
    });

    tour.addStep({
      title: t('discover.tour.leaderboard.title'),
      text: t('discover.tour.leaderboard.text'),
      attachTo: {
        element: '.leaderboard-container',
        on: 'top'
      },
      buttons: [
        {
          text: t('discover.tour.buttons.back'),
          action: tour.back
        },
        {
          text: t('discover.tour.buttons.finish'),
          action: tour.complete
        }
      ]
    });

    // Complete Event Handler
    tour.on('complete', () => {
      setIsTourActive(false);
      sessionStorage.removeItem('inTour');
      sessionStorage.removeItem('currentTourStep');
      document.body.removeChild(overlay);
      
      // Mark the tour as seen
      fetch(`${apiUrl}/tutorial/progress`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: user.id,
          section_name: 'discover_tour',
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (!data.success) {
            console.error('Failed to update tutorial progress:', data.message);
          }
        })
        .catch(error => console.error('Error updating tutorial progress:', error));
    });

    // Cancel Event Handler
    tour.on('cancel', () => {
      setIsTourActive(false);
      sessionStorage.removeItem('inTour');
      sessionStorage.removeItem('currentTourStep');
      document.body.removeChild(overlay);
    });

    tour.start();
  }, [t, user.id]);

  useEffect(() => {
    const inTour = sessionStorage.getItem('inTour');
    const currentTourStep = sessionStorage.getItem('currentTourStep');

    if (inTour === 'true' && currentTourStep === 'discover_start') {
      // Short delay to ensure the component is fully rendered
      setTimeout(() => {
        handleDiscoverTour();
      }, 100);
    }
  }, [handleDiscoverTour]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    // No longer triggers fetchProfiles
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const trimmedSearchTerm = searchTerm.trim();
    fetchProfiles(trimmedSearchTerm);
  };

  const handleProfileClick = (id) => {
    navigate(`/public_profile/${id}`);
  };

  const handleVideoLanguageChange = (event) => {
    setSelectedVideoLanguage(event.target.value);
    setCurrentVideoIndex(0); // Reset to first video when changing language
  };

  const handleLeaderboardLanguageChange = useCallback((event) => {
    setSelectedLanguage(event.target.value);
  }, []);

  const handleLevelChange = (e) => {
    setSelectedLevel(e.target.value);
  };

  const handleSortCriteriaChange = (e) => {
    setSortCriteria(e.target.value);
  };

  const getProfilePictureSrc = (profilePicture) => {
    if (profilePicture.startsWith('http')) {
      return profilePicture;
    }
    return `${apiUrl}/${profilePicture}`;
  };

  const sortedLeaderboardData = {
    'vocabRace': vocabRaceData,
    'vocabRaceGames': vocabRaceGamesData,
    'chatMessages': leaderboardData,
    'videoCalls': videoCallsData,
    'storyChainGames': leaderboardData,
  }[sortCriteria];

  const toggleLanguageSelection = (language) => {
    if (!userLanguages.includes(language)) {
      setSelectedLanguages((prevState) =>
        prevState.includes(language)
          ? prevState.filter((l) => l !== language)
          : [...prevState, language]
      );
    }
  };

  const handleAddLanguages = async () => {
    const newLanguages = selectedLanguages.filter(
      (language) => !userLanguages.includes(language)
    );
    if (newLanguages.length === 0) return;

    try {
      const response = await fetch(`${apiUrl}/user/interests`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: user.id, languages: newLanguages }),
      });

      const data = await response.json();
      if (data.success) {
        setUserLanguages(prev => [...prev, ...newLanguages]);
        setSelectedLanguages([]);
        closeLanguageModal();
      }
    } catch (error) {
      console.error("Error adding languages:", error);
    }
  };

  const openLanguageModal = () => {
    setLanguageModalIsOpen(true);
    const available = Object.keys(languageFlags).filter(
      (language) => !userLanguages.includes(language)
    );
    setAvailableLanguages(available);
  };

  const closeLanguageModal = () => {
    setLanguageModalIsOpen(false);
    setSelectedLanguages([]);
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex((prev) => 
      prev === 0 ? videoIds.length - 1 : prev - 1
    );
  };

  const handleNextVideo = () => {
    setCurrentVideoIndex((prev) => 
      prev === videoIds.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <div className={`discover-page ${isTourActive ? 'tour-active' : ''}`}>

      <div className="discover-grid">
        <div className="discover-videos-container">
          <div className="discover-video-wrapper">
            <div className="discover-video-deck">
              <img 
                src={videoThumbnails[currentVideoIndex]} 
                alt="Video Thumbnail" 
                className="discover-video-thumbnail" 
              />
              <div className="discover-video-content">
                <h2 className="discover-video-title">{t('discover.header.main')}</h2>
                <div className="video-actions">
                  <a 
                    href={`https://www.youtube.com/watch?v=${videoIds[currentVideoIndex]}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="watch-youtube-button"
                  >
                    Watch on YouTube
                  </a>
                  <div className="language-select-wrapper">
                    <select 
                      value={selectedVideoLanguage}
                      onChange={handleVideoLanguageChange}
                      className="video-language-select"
                    >
                      <option value="trending">Trending</option>
                      {Object.keys(languageImages).map((lang) => (
                        <option key={lang} value={lang.toLowerCase()}>
                          {t(`languages.${lang.toLowerCase()}`)}
                        </option>
                      ))}
                    </select>
                    <svg className="dropdown-icon" viewBox="0 0 24 24" width="16" height="16">
                      <path 
                        fill="currentColor" 
                        d="M7 10l5 5 5-5z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="video-nav-arrows">
                <button 
                  className="video-nav-button" 
                  onClick={handlePrevVideo}
                  aria-label="Previous video"
                >
                  ←
                </button>
                <button 
                  className="video-nav-button" 
                  onClick={handleNextVideo}
                  aria-label="Next video"
                >
                  →
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* New Spotify Section */}
        <div className="spotify-section">
          <Link to="/spotify-player" className="spotify-link">
            <div className="spotify-content">
              <h2 className="spotify-title">
              <span className="travel-feature-beta">BETA</span>
              <span className="speakeasy-text">{t('discover.spotify.title.speakeasy')}</span>
                <span className="spotify-x">{t('discover.spotify.title.x')}</span>
                <span className="spotify-text">{t('discover.spotify.title.spotify')}</span>
              </h2>
              <img src="/images/spotify-logo.png" alt="Spotify Logo" className="spotify-logo" />
            </div>
            <p className="video-chat-description">{t('discover.spotify.description')}</p>
          </Link>
        </div>

        {/* Replace travel section with new component */}
        <TravelSection />
      </div>

      <VideoChatSection 
        userLanguages={userLanguages} 
        onAddLanguages={openLanguageModal} 
      />
      <AIConversationSection 
        userLanguages={userLanguages} 
        onAddLanguages={openLanguageModal} 
      />

      <h1 className="discover-header">{t('discover.header.profiles')}</h1>
      <p className="discover-subtitle">{t('discover.subtitle.profiles')}</p>
      <div className="discover-search-container">
        <form onSubmit={handleSearchSubmit} className="search-form">
          <input
            type="text"
            placeholder={t('discover.search.placeholder')}
            value={searchTerm}
            onChange={handleSearchChange}
            className="discover-search-bar"
            aria-label={t('discover.search.placeholder')}
          />
          <button type="submit" className="search-button" disabled={isLoading}>
            {isLoading ? t('discover.search.button.loading') : t('discover.search.button.default')}
          </button>
        </form>
      </div>

      {isLoading ? (
        <div className="loading-skeleton">
          <p>Loading profiles...</p>
        </div>
      ) : (
        <div className="discover-profiles-grid">
          {profiles.length > 0 ? (
            profiles
              .slice(0, searchTerm.trim() ? 4 : profiles.length) // Limit to 4 when searching
              .map((profile) => (
                <div key={profile.id} className="discover-profile-card" onClick={() => handleProfileClick(profile.id)}>
                  <div className="discover-profile-pic">
                    {profile.profile_picture ? (
                      <img src={getProfilePictureSrc(profile.profile_picture)} alt={`${profile.display_name}'s profile`} />
                    ) : (
                      <div className="discover-profile-placeholder">{profile.display_name.charAt(0)}</div>
                    )}
                  </div>
                  <div className="discover-profile-info">
                    <h2>
                      {profile.display_name}
                      {profile.is_verified && (
                        <img 
                          src="/images/verified-badge.webp" 
                          alt="Verified User" 
                          className="verified-badge" 
                          title="Verified Account"
                        />
                      )}
                      {profile.is_pro && (
                        <span className="pro-badge" title="Pro Account">PRO</span>
                      )}
                    </h2>
                    <div className="discover-languages-container">
                      {profile.languages.map((lang) => (
                        <img key={lang.language} src={languageImages[lang.language]} alt={lang.language} className="discover-language-flag" />
                      ))}
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <p className="no-results-message">No profiles found.</p>
          )}
        </div>
      )}

      <h3 className="discover-header">{t('discover.header.leaderboard')}</h3>
      <p className="discover-subtitle">{t('discover.subtitle.leaderboard')}</p>

      <div className="leaderboard-controls">
        <label htmlFor="rank-by">{t('discover.leaderboard.rankBy')}</label>
        <select id="rank-by" value={sortCriteria} onChange={handleSortCriteriaChange}>
          <option value="chatMessages">{t('discover.leaderboard.options.chatMessages')}</option>
          <option value="videoCalls">{t('discover.leaderboard.options.videoCalls')}</option>
          <option value="vocabRace">{t('discover.leaderboard.options.vocabRace')}</option>
          <option value="vocabRaceGames">{t('discover.leaderboard.options.vocabRaceGames')}</option>
          <option value="storyChainGames">{t('discover.leaderboard.options.storyChainGames')}</option>
        </select>
        
        {(sortCriteria === 'vocabRace' || sortCriteria === 'vocabRaceGames' || sortCriteria === 'storyChainGames') && (
          <select 
            id="language" 
            value={selectedLanguage} 
            onChange={handleLeaderboardLanguageChange}
          >
            {Object.keys(languageImages).map((lang) => (
              <option key={lang} value={lang}>
                {t(`languages.${lang.toLowerCase()}`)}
              </option>
            ))}
          </select>
        )}
        
        {(sortCriteria === 'vocabRace' || sortCriteria === 'vocabRaceGames') && (
          <select id="level" value={selectedLevel} onChange={handleLevelChange}>
            <option value="easy">{t('discover.leaderboard.difficulty.easy')}</option>
            <option value="medium">{t('discover.leaderboard.difficulty.medium')}</option>
            <option value="hard">{t('discover.leaderboard.difficulty.hard')}</option>
          </select>
        )}
      </div>

      <div className="leaderboard-container">
        {sortedLeaderboardData.length > 0 ? (
          sortedLeaderboardData.map((user, index) => (
            <div key={user.userId} className="leaderboard-card" onClick={() => handleProfileClick(user.userId)}>
              <div className="leaderboard-rank">
                <span>{index + 1}</span>
              </div>
              <div className="leaderboard-profile-pic">
                <img src={getProfilePictureSrc(user.profilePicture)} alt={user.displayName} />
              </div>
              <div className="leaderboard-info">
                <h3>
                  {user.displayName}
                  {user.is_verified && (
                    <img 
                      src="/images/verified-badge.webp" 
                      alt="Verified User" 
                      className="verified-badge" 
                      title="Verified Account"
                    />
                  )}
                  {user.is_pro && (
                    <span className="pro-badge" title="Pro User">PRO</span>
                  )}
                </h3>
                {sortCriteria === 'chatMessages' && (
                  <>
                    <p><strong>{t('discover.leaderboard.stats.totalMessages')}:</strong> {user.totalMessages}</p>
                  </>
                )}
                {sortCriteria === 'vocabRace' && (
                  <p><strong>{t('discover.leaderboard.stats.averageTime')}:</strong> {Number(user.avgTime).toFixed(2)} seconds</p>
                )}
                {(sortCriteria === 'vocabRaceGames' || sortCriteria === 'storyChainGames') && (
                  <p><strong>{t('discover.leaderboard.stats.totalGames')}:</strong> {user.totalGames}</p>
                )}
                {sortCriteria === 'videoCalls' && (
                  <>
                    <p><strong>{t('discover.leaderboard.stats.totalVideoCalls')}:</strong> {user.totalCalls}</p>
                    <p><strong>{t('discover.leaderboard.stats.totalDuration')}:</strong> {Math.round(user.totalDuration / 60)} mins</p>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>{t('discover.leaderboard.noData')}</p>
        )}
      </div>
      <FeedbackForm />

      {/* Language Selection Modal */}
      <Modal
        isOpen={languageModalIsOpen}
        onRequestClose={closeLanguageModal}
        contentLabel={t('dashboard.languageModal.title')}
        className="modal fade-in"
        overlayClassName="overlay fade-in"
      >
        <h2 className="fade-in">{t('dashboard.languageModal.title')}</h2>
        <div className="languages-container-modal fade-in">
          {availableLanguages.map((language) => (
            <div
              key={language}
              className={`language-item fade-in ${
                selectedLanguages.includes(language) ? 'selected' : ''
              } ${userLanguages.includes(language) ? 'disabled' : ''}`}
              onClick={() => toggleLanguageSelection(language)}
            >
              <img
                src={languageFlags[language]}
                alt={t('languages.' + language.toLowerCase())}
                className="fade-in"
              />
              <p className="fade-in">{t('languages.' + language.toLowerCase())}</p>
              {selectedLanguages.includes(language) && (
                <span className="checkmark fade-in">✔️</span>
              )}
            </div>
          ))}
        </div>
        <button
          onClick={handleAddLanguages}
          className="add-languages-button fade-in"
        >
          {t('dashboard.languageModal.buttons.add')}
        </button>
        <button
          onClick={closeLanguageModal}
          className="close-modal-button fade-in"
        >
          {t('dashboard.languageModal.buttons.close')}
        </button>
      </Modal>
    </div>
  );
};

export default Discover;
