import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-container">
        <h1>Speakeasy Privacy Policy</h1>
        <p className="last-updated">Last updated: December 2024</p>

        <section>
          <h2>1. Information We Collect</h2>
          <p>When you use Speakeasy, we collect:</p>
          <ul>
            <li>Information provided through Google Sign-In (email, name, profile picture)</li>
            <li>Language preferences and learning progress</li>
            <li>Chat messages and communication data</li>
            <li>Usage statistics and interaction data</li>
          </ul>
        </section>

        <section>
          <h2>2. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Provide and improve our language learning services</li>
            <li>Personalize your learning experience</li>
            <li>Connect you with other language learners</li>
            <li>Send important updates about our service</li>
          </ul>
        </section>

        <section>
          <h2>3. Data Security</h2>
          <p>We implement security measures to protect your personal information and maintain data privacy.</p>
        </section>

        <section>
          <h2>4. Third-Party Services</h2>
          <p>We use services like Google Authentication, Firebase, and other tools to provide our service. These services have their own privacy policies.</p>
        </section>

        <section>
          <h2>5. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal data</li>
            <li>Request data correction or deletion</li>
            <li>Opt-out of certain data collection</li>
          </ul>
        </section>

        <section>
          <h2>6. Contact Us</h2>
          <p>For privacy-related questions</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 