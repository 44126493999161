import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './SignIn.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SignIn = () => {
  const [formData, setFormData] = useState({
    identifier: '', // This can be either email or display name
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const { login } = useAuth();
  const navigate = useNavigate();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [submitError, setSubmitError] = useState('');

  const backgroundVideos = useMemo(() => [
    '../../videos/stock5.mp4',
    '../../videos/stock4.mp4',
  ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentVideoIndex === backgroundVideos.length - 1) {
        const slider = document.querySelector('.video-slider');
        if (slider) {
          slider.style.transition = 'none';
          setCurrentVideoIndex(0);
          setTimeout(() => {
            slider.style.transition = 'transform 1.5s ease-in-out';
          }, 50);
        }
      } else {
        setCurrentVideoIndex(prev => prev + 1);
      }
    }, 8000);

    return () => clearInterval(interval);
  }, [currentVideoIndex, backgroundVideos.length]);

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission for regular login
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitError(''); // Clear any previous errors
    
    fetch(`${apiUrl}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          login(data.user);
          navigate('/home');
        } else {
          setSubmitError(data.message || 'Invalid credentials. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error logging in:', error);
        setSubmitError('An unexpected error occurred. Please try again later.');
      });
  };

  // Comment out the function
  /*
  const handleOAuth = (provider) => {
    if (provider === 'google') {
      window.location.href = `${apiUrl}/auth/google`;
    }
  };
  */

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="sign-in-page">
      <div className="sign-in-left">
        <div 
          className="video-slider" 
          style={{ transform: `translateX(-${currentVideoIndex * 33.35}%)` }}
        >
          {backgroundVideos.map((video, index) => (
            <div key={video} className="video-slide">
              <video
                className="sign-in-hero-video"
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={video} type="video/mp4" />
              </video>
            </div>
          ))}
        </div>
        <div className="sign-in-left-content">
          {/* Any additional content */}
        </div>
      </div>
      <div className="sign-in-right">
        <Link to="/home">
          <img src="/images/speakeasylogo.png" alt="SpeakEasy Logo" className="sign-in-logo" />
        </Link>
        <h2 className="sign-in-title">Sign In</h2>
        
        <div className="error-message-container">
          <TransitionGroup>
            {submitError && (
              <CSSTransition
                key={submitError}
                timeout={300}
                classNames="error-message"
              >
                <div className="error-message">
                  {submitError}
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>

        <form onSubmit={handleSubmit} className="sign-in-form">
          <div className="sign-in-form-group">
            <label htmlFor="identifier" className="sign-in-label">Email or Display Name:</label>
            <input
              type="text"
              id="identifier"
              name="identifier"
              value={formData.identifier}
              onChange={handleChange}
              required
              className="sign-in-input"
            />
          </div>
          <div className="sign-in-form-group">
            <label htmlFor="password" className="sign-in-label">Password:</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="sign-in-input"
              />
              <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>

          <button type="submit" className="register-get-started-button">Sign In</button>
        </form>

        <div className="sign-in-forgot-password">
          <span>Forgot your password? </span>
          <Link to="/forgotmypassword" className="sign-in-forgot-password-link">Click Here</Link>
        </div>

        <div className="sign-in-or-divider">
          <span>OR</span>
        </div>

        {/* Comment out only the Google button section */}
        {/*
        <div className="sign-in-oauth-buttons">
          <div className="sign-in-oauth-button" onClick={() => handleOAuth('google')}>
            <img src="/images/google-icon.webp" alt="Google" className="sign-in-oauth-icon" />
            Continue with Google
          </div>
        </div>
        */}

        <div className="sign-in-signup-link">
          <span>Don't have an account? </span>
          <Link to="/selectlanguages" className="sign-in-signup-link-text">Sign Up</Link>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
