import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Success.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Success = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const session_id = params.get('session_id');
        const user_id = params.get('user_id');

        console.log('Session ID:', session_id);
        console.log('User ID:', user_id);

        if (!session_id || !user_id) {
          throw new Error('Missing session information');
        }

        const response = await fetch(`${apiUrl}/subscription/verify-payment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            session_id,
            user_id
          }),
          credentials: 'include'
        });

        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.error || 'Failed to verify payment');
        }

        if (!data.success) {
          throw new Error(data.message || 'Payment verification failed');
        }

        // Successful verification
        setTimeout(() => {
          navigate('/dashboard');
        }, 5000);

      } catch (err) {
        setError(err.message);
        console.error('Payment verification error:', err);
      } finally {
        setIsVerifying(false);
      }
    };

    verifyPayment();
  }, [location, navigate]);

  return (
    <div className="success-page">
      <div className="success-content">
        {isVerifying ? (
          <>
            <div className="loading-spinner"></div>
            <h2>{t('success.verifying')}</h2>
          </>
        ) : error ? (
          <>
            <div className="error-icon">❌</div>
            <h2>{t('success.error.title')}</h2>
            <p className="error-message">{error}</p>
            <button 
              className="return-button"
              onClick={() => navigate('/dashboard')}
            >
              {t('success.error.return')}
            </button>
          </>
        ) : (
          <>
            <div className="success-icon">✅</div>
            <h2>{t('success.thank.you')}</h2>
            <p>{t('success.subscription.activated')}</p>
            <p className="redirect-message">{t('success.redirect')}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default Success; 