import React, { useState, useEffect, useContext, useRef} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import Modal from "react-modal";
import BadgeModal from "./BadgeModal"; // Importing the consolidated BadgeModal component
import WelcomeModal from './WelcomeModal'; // Import the Welcome Modal
import "./Dashboard.css";
import "./BadgeModal.css"; // Importing the CSS for the BadgeModal
import "./JoyrideTour.css";
import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import SpeakeasyProModal from '../../components/SpeakeasyProModal/SpeakeasyProModal';
import { badgeDetails, getTranslatedBadgeDetails } from '../../utils/BadgeDetails'; // Import badgeDetails
import { useTranslation } from 'react-i18next';


const apiUrl = process.env.REACT_APP_API_BASE_URL;

const languageImages = {
  Spanish: '/images/mexico_flag.png',
  Japanese: '/images/icon_flag-ja.svg',
  Portuguese: '/images/icon_flag-pt.svg',
  French: '/images/icon_flag-fr.svg',
  German: '/images/icon_flag-de.svg',
  Italian: '/images/icon_flag-it.svg',
  Mandarin: '/images/icon_flag-zh.svg',
  Arabic: '/images/icon_flag-ar.svg',
  Cantonese: '/images/icon_flag-hk.png',
  Korean: '/images/icon_flag-ko.svg',
  Russian: '/images/icon_flag-ru.svg',
  Tagalog: '/images/icon_flag_pp.png',
  Greek: '/images/icon_flag_gr.png',
  Hindi: '/images/icon_flag_in.png',

};


const languageChats = [
  'spanish',
  'japanese',
  'portuguese',
  'french',
  'german',
  'italian',
  'mandarin',
  'arabic',
  'cantonese',
  'korean',
  'russian',
  'greek',
  'tagalog',
];

const getMessageCountAndLevel = async (userId, displayName) => {
  const fetchMessageCounts = languageChats.map(async (language) => {
    const q = query(
      collection(firestore, "chats", language, "messages"),
      where("user_id", "==", userId),
      where("display_name", "==", displayName)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  });

  const messageCounts = await Promise.all(fetchMessageCounts);
  const messageCount = messageCounts.reduce((acc, count) => acc + count, 0);

  const levels = badgeDetails.firstMessage.levels;
  let badgeLevel = null;

  if (messageCount >= levels.vibranium.count) {
    badgeLevel = "vibranium";
  } else if (messageCount >= levels.gold.count) {
    badgeLevel = "gold";
  } else if (messageCount >= levels.silver.count) {
    badgeLevel = "silver";
  } else if (messageCount >= levels.bronze.count) {
    badgeLevel = "bronze";
  }

  


  return { messageCount, badgeLevel };
};

const getVideoCallCountAndLevel = async (userId) => {
  try {
    const response = await fetch(`${apiUrl}/video_call/stats?user_id=${userId}`);
    const data = await response.json();
    
    if (!data.success) {
      console.error('Failed to fetch video call stats:', data.message);
      return { callCount: 0, badgeLevel: null };
    }

    const callCount = data.stats.total_calls;
    const levels = badgeDetails.connector.levels;
    let badgeLevel = null;

    if (callCount >= levels.vibranium.count) {
      badgeLevel = "vibranium";
    } else if (callCount >= levels.gold.count) {
      badgeLevel = "gold";
    } else if (callCount >= levels.silver.count) {
      badgeLevel = "silver";
    } else if (callCount >= levels.bronze.count) {
      badgeLevel = "bronze";
    }

    return { callCount, badgeLevel };
  } catch (error) {
    console.error('Error fetching video call count:', error);
    return { callCount: 0, badgeLevel: null };
  }
};

function Dashboard() {
  const { user } = useContext(AuthContext);
  const [interests, setInterests] = useState([]);
  const [languageLevels, setLanguageLevels] = useState({});
  const [badges, setBadges] = useState([]);
  const [currentBadgeLevels, setCurrentBadgeLevels] = useState({});
  const [badgeModalIsOpen, setBadgeModalIsOpen] = useState(false);
  const [languageModalIsOpen, setLanguageModalIsOpen] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState(null);
  const [longestStreak, setLongestStreak] = useState(0);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showProModal, setShowProModal] = useState(false);
  const [isTourActive, setIsTourActive] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Refs to prevent unnecessary re-renders
  const currentBadgeLevelsRef = useRef(currentBadgeLevels);
  const longestStreakRef = useRef(longestStreak);

  // Keep refs updated
  useEffect(() => {
    currentBadgeLevelsRef.current = currentBadgeLevels;
  }, [currentBadgeLevels]);

  useEffect(() => {
    longestStreakRef.current = longestStreak;
  }, [longestStreak]);

  useEffect(() => {
    const fetchUserDataAndStreak = async () => {
      if (user && user.id) {
        try {
          const badgeUpdates = [];

          const [interestsResponse, streakResponse, friendsResponse, gamesPlayedResponse, videoCallResponse] = await Promise.all([
            fetch(`${apiUrl}/user/interests?user_id=${user.id}`),
            fetch(`${apiUrl}/get_streak_count?user_id=${user.id}`),
            fetch(`${apiUrl}/get_num_friends?user_id=${user.id}`),
            fetch(`${apiUrl}/games_played/${user.id}/Vocabulary%20Race`),
            getVideoCallCountAndLevel(user.id)
          ]);

          const interestsData = await interestsResponse.json();
          const streakData = await streakResponse.json();
          const friendsData = await friendsResponse.json();
          const gamesPlayedData = await gamesPlayedResponse.json();

          if (interestsData.success) {
            setInterests(interestsData.interests);
            setLanguageLevels(interestsData.language_levels);

            const languagesCount = interestsData.interests.length;
            let mrWorldwideLevel = determineBadgeLevel(languagesCount, badgeDetails.mrWorldwide.levels);

            if (mrWorldwideLevel && currentBadgeLevelsRef.current['mrWorldwide'] !== mrWorldwideLevel) {
              badgeUpdates.push({
                badge_name: 'Mr. Worldwide',
                level: mrWorldwideLevel
              });
              setCurrentBadgeLevels(prev => ({ ...prev, mrWorldwide: mrWorldwideLevel }));
              setBadges(prev => [...prev, { badge: 'mrWorldwide', level: mrWorldwideLevel }]);
            }

            const { badgeLevel } = await getMessageCountAndLevel(user.id, user.display_name);
            if (badgeLevel && currentBadgeLevelsRef.current['firstMessage'] !== badgeLevel) {
              badgeUpdates.push({
                badge_name: 'Chat no GPT',
                level: badgeLevel
              });
              setCurrentBadgeLevels(prev => ({ ...prev, firstMessage: badgeLevel }));
              setBadges(prev => [...prev, { badge: 'firstMessage', level: badgeLevel }]);
            }

            const available = Object.keys(languageImages).filter(
              (language) => !interestsData.interests.includes(language)
            );
            setAvailableLanguages(available);

            const { badgeLevel: connectorBadgeLevel } = videoCallResponse;
            if (connectorBadgeLevel && currentBadgeLevelsRef.current['connector'] !== connectorBadgeLevel) {
              badgeUpdates.push({
                badge_name: 'Connector',
                level: connectorBadgeLevel
              });
              setCurrentBadgeLevels(prev => ({ ...prev, connector: connectorBadgeLevel }));
              setBadges(prev => [...prev, { badge: 'connector', level: connectorBadgeLevel }]);
            }

            if (badgeUpdates.length > 0) {
              await updateBadges(user.id, badgeUpdates);
            }
          } else {
            console.error('Failed to fetch interests:', interestsData.message);
          }

          if (streakData.longest_streak !== undefined) {
            if (streakData.longest_streak !== longestStreakRef.current) {
              setLongestStreak(streakData.longest_streak);

              const streakBadgeLevel = determineBadgeLevel(streakData.longest_streak, badgeDetails.consistentCapper.levels);
              if (streakBadgeLevel && currentBadgeLevelsRef.current['consistentCapper'] !== streakBadgeLevel) {
                badgeUpdates.push({
                  badge_name: 'Woke Up Like This',
                  level: streakBadgeLevel
                });
                setCurrentBadgeLevels(prev => ({ ...prev, consistentCapper: streakBadgeLevel }));
                setBadges(prev => [...prev, { badge: 'consistentCapper', level: streakBadgeLevel }]);
              }
            }
          }

          if (friendsData.success) {
            const numFriends = friendsData.num_friends;
            const friendBadgeLevel = determineBadgeLevel(numFriends, badgeDetails.friendshipGoals.levels);

            if (friendBadgeLevel && currentBadgeLevelsRef.current['friendshipGoals'] !== friendBadgeLevel) {
              badgeUpdates.push({
                badge_name: 'Friendship Goals',
                level: friendBadgeLevel
              });
              setCurrentBadgeLevels(prev => ({ ...prev, friendshipGoals: friendBadgeLevel }));
              setBadges(prev => [...prev, { badge: 'friendshipGoals', level: friendBadgeLevel }]);
            }
          }

          if (gamesPlayedData.success) {
            const gamesPlayed = gamesPlayedData.games_played;
            const maxVerstappenLevel = determineBadgeLevel(gamesPlayed, badgeDetails.MaxVerstappen.levels);

            if (maxVerstappenLevel && currentBadgeLevelsRef.current['MaxVerstappen'] !== maxVerstappenLevel) {
              badgeUpdates.push({
                badge_name: 'Max Verstappen',
                level: maxVerstappenLevel
              });
              setCurrentBadgeLevels(prev => ({ ...prev, MaxVerstappen: maxVerstappenLevel }));
              setBadges(prev => [...prev, { badge: 'MaxVerstappen', level: maxVerstappenLevel }]);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchUserDataAndStreak();

    const tutorialProgress = sessionStorage.getItem('tutorialProgress');
  
    if (!tutorialProgress) {
      fetch(`${apiUrl}/tutorial/progress?user_id=${user.id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success && !data.progress.some((section) => section.section_name === 'welcome_modal' && section.seen)) {
            setShowWelcomeModal(true); // Show the modal if the user hasn't seen it
          }
          sessionStorage.setItem('tutorialProgress', JSON.stringify(data.progress));
        })
        .catch((error) => console.error('Error fetching tutorial progress:', error));
    } else {
      const progress = JSON.parse(tutorialProgress);
      if (!progress.some((section) => section.section_name === 'welcome_modal' && section.seen)) {
        setShowWelcomeModal(true);
      }
    }
  }, [user]); // Only trigger on user change

  const handleStartTour = () => {
    setShowWelcomeModal(false);
    setIsTourActive(true);

    // Mark as seen in sessionStorage
    const tutorialProgress = JSON.parse(sessionStorage.getItem('tutorialProgress')) || [];
    const updatedProgress = tutorialProgress.map((section) =>
      section.section_name === 'welcome_modal' ? { ...section, seen: true } : section
    );
  
    if (!updatedProgress.some((section) => section.section_name === 'welcome_modal')) {
      updatedProgress.push({ section_name: 'welcome_modal', seen: true });
    }
  
    sessionStorage.setItem('tutorialProgress', JSON.stringify(updatedProgress));
  
    // Send request to backend to mark tour as seen
    fetch(`${apiUrl}/tutorial/progress`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: user.id,
        section_name: 'welcome_modal',
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          console.error('Failed to update tutorial progress:', data.message);
        }
      })
      .catch(error => console.error('Error updating tutorial progress:', error));
  
    // Create an overlay
    const overlay = document.createElement('div');
    overlay.className = 'tour-overlay';
    document.body.appendChild(overlay);

    const tour = new Shepherd.Tour({
      defaultStepOptions: {
        cancelIcon: {
          enabled: true
        },
        scrollTo: { behavior: 'smooth', block: 'center' },
        classes: 'custom-tour-class',
      },
      useModalOverlay: true,
    });
  
    // Define your steps
    tour.addStep({
      title: t('dashboard.tour.languages.title'),
      text: t('dashboard.tour.languages.text'),
      attachTo: {
        element: '.language-card',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('dashboard.tour.buttons.next'),
          action: tour.next,
        },
      ],
    });
  
    tour.addStep({
      title: t('dashboard.tour.addLanguages.title'),
      text: t('dashboard.tour.addLanguages.text'),
      attachTo: {
        element: '.add-language-button',
        on: 'right',
      },
      buttons: [
        {
          text: t('dashboard.tour.buttons.back'),
          action: tour.back,
        },
        {
          text: t('dashboard.tour.buttons.next'),
          action: tour.next,
        },
      ],
    });
  
    tour.addStep({
      title: t('dashboard.tour.badges.title'),
      text: t('dashboard.tour.badges.text'),
      attachTo: {
        element: '.account-badges-container',
        on: 'top',
      },
      buttons: [
        {
          text: t('dashboard.tour.buttons.back'),
          action: tour.back,
        },
        {
          text: t('dashboard.tour.buttons.next'),
          action: tour.next,
        },
      ],
    });

    tour.addStep({
      title: t('dashboard.tour.navbar.title'),
      text: t('dashboard.tour.navbar.text'),
      attachTo: {
        element: '.navbar', // Make sure to add this class to your streak icon element
        on: 'bottom',
      },
      buttons: [
        {
          text: t('dashboard.tour.buttons.back'),
          action: tour.back,
        },
        {
          text: t('dashboard.tour.buttons.next'),
          action: tour.next,
        },
      ],
    });
  
    tour.addStep({
      title: t('dashboard.tour.streak.title'),
      text: t('dashboard.tour.streak.text'),
      attachTo: {
        element: '.streak-icon', // Make sure to add this class to your streak icon element
        on: 'bottom',
      },
      buttons: [
        {
          text: t('dashboard.tour.buttons.back'),
          action: tour.back,
        },
        {
          text: t('dashboard.tour.buttons.next'),
          action: tour.next,
        },
      ],
    });
  
    // Modify the last step to remove the overlay when navigating away
    tour.addStep({
      title: t('dashboard.tour.chatRooms.title'),
      text: t('dashboard.tour.chatRooms.text'),
      buttons: [
        {
          text: t('dashboard.tour.buttons.back'),
          action: tour.back,
        },
        {
          text: t('dashboard.tour.buttons.next'),
          action: () => {
            sessionStorage.setItem('currentTourStep', 'chatrooms_start');
            sessionStorage.setItem('inTour', 'true');
            tour.complete(); // This will trigger the complete handler
          },
        },
      ],
    });
  
    // Modify the cancel event handler
    tour.on('cancel', () => {
      setIsTourActive(false);
      sessionStorage.removeItem('inTour');
      sessionStorage.removeItem('currentTourStep');
      document.body.removeChild(overlay);
    });
  
    // Modify the complete event handler
    tour.on('complete', () => {
      setIsTourActive(false);
      document.body.removeChild(overlay);
      navigate('/chatrooms');
    });
  
    tour.start();
  };
  
  
  const closeWelcomeModal = () => {
    setShowWelcomeModal(false);
  
    fetch(`${apiUrl}/tutorial/progress`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user_id: user.id,
        section_name: 'welcome_modal',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const tutorialProgress = JSON.parse(sessionStorage.getItem('tutorialProgress')) || [];
          const updatedProgress = tutorialProgress.map((section) =>
            section.section_name === 'welcome_modal' ? { ...section, seen: true } : section
          );
          if (!updatedProgress.some((section) => section.section_name === 'welcome_modal')) {
            updatedProgress.push({ section_name: 'welcome_modal', seen: true });
          }
          sessionStorage.setItem('tutorialProgress', JSON.stringify(updatedProgress));
        } else {
          console.error('Failed to update tutorial progress:', data.message);
        }
      })
      .catch((error) => console.error('Error updating tutorial progress:', error));
  };

  
  
  const determineBadgeLevel = (count, levels = {}) => {
    if (levels.vibranium && count >= levels.vibranium.count) {
      return 'vibranium';
    } else if (levels.gold && count >= levels.gold.count) {
      return 'gold';
    } else if (levels.silver && count >= levels.silver.count) {
      return 'silver';
    } else if (levels.bronze && count >= levels.bronze.count) {
      return 'bronze';
    }
    return null;
  };




  useEffect(() => {
    const elements = document.querySelectorAll(".fade-in");
    if (elements.length > 0) {
      // Check if elements exist
      elements.forEach((element) => {
        element.classList.add("show");
      });
    }
  }, [interests, languageLevels, badges]);

  const handleLanguageClick = (language) => {
    if (!isTourActive) {
      navigate(`/language/${language.toLowerCase()}`);
    }
  };

  const closeBadgeModal = () => {
    setBadgeModalIsOpen(false);
  };

  const openLanguageModal = () => {
    if (!isTourActive) {
      setLanguageModalIsOpen(true);
    }
  };

  const closeLanguageModal = () => {
    setLanguageModalIsOpen(false);
  };

  const toggleLanguageSelection = (language) => {
    if (!interests.includes(language)) {
      setSelectedLanguages((prevState) =>
        prevState.includes(language)
          ? prevState.filter((l) => l !== language)
          : [...prevState, language]
      );
    }
  };

  const handleAddLanguages = async () => {
    const newLanguages = selectedLanguages.filter(
      (language) => !interests.includes(language)
    );
    if (newLanguages.length === 0) {
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/user/interests`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: user.id, languages: newLanguages }),
      });

      const data = await response.json();
      if (data.success) {
        const updatedInterests = [...interests, ...newLanguages];
        setInterests(updatedInterests);
        setSelectedLanguages([]);

        if (
          updatedInterests.length >= 3 &&
          !badges.some((b) => b.badge === "mrWorldwide")
        ) {
          setBadges((prevBadges) => [
            ...prevBadges,
            { badge: "mrWorldwide", level: "unlocked" },
          ]);
        }

        closeLanguageModal();
      } else {
        console.error("Failed to add languages:", data.message);
      }
    } catch (error) {
      console.error("Error adding languages:", error);
    }
  };

  const handleOpenProModal = () => {
    setShowProModal(true);
  };

  const handleCloseProModal = () => {
    setShowProModal(false);
  };

  // Instead, use this function
  const handleBadgeClick = (badgeKey) => {
    if (!isTourActive) {
      const userBadge = badges.find((b) => b.badge === badgeKey);
      const badge = {
        badge: badgeKey,
        level: userBadge ? userBadge.level : 'locked',
        name: t(`badges.${badgeKey}.name`),
        description: t(`badges.${badgeKey}.baseDescription`),
      };
      setSelectedBadge(badge);
      setBadgeModalIsOpen(true);
    }
  };

  const updateBadges = async (userId, badgeUpdates) => {
    try {
      console.log('Sending badge updates:', badgeUpdates); // Debug log

      const updateBadgeResponse = await fetch(`${apiUrl}/update_badges`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_id: userId,
          badges: badgeUpdates.map(badge => ({
            badge_name: badge.badge_name || badge.badge, // Handle both formats
            level: badge.level
          }))
        }),
      });

      const updateBadgeData = await updateBadgeResponse.json();

      if (!updateBadgeData.success) {
        console.error('Failed to update badges:', updateBadgeData.message);
      } else {
        // Handle individual badge update results
        updateBadgeData.results.forEach(result => {
          if (!result.success) {
            console.error(`Failed to update ${result.badge_name}:`, result.message);
          } else {
            console.log(`Successfully updated ${result.badge_name} to ${result.level}`); // Debug log
          }
        });

        // Update local state to reflect the changes
        setBadges(prevBadges => {
          const updatedBadges = [...prevBadges];
          badgeUpdates.forEach(update => {
            const badgeName = update.badge_name || update.badge;
            const existingIndex = updatedBadges.findIndex(b => b.badge === badgeName);
            if (existingIndex !== -1) {
              updatedBadges[existingIndex] = { badge: badgeName, level: update.level };
            } else {
              updatedBadges.push({ badge: badgeName, level: update.level });
            }
          });
          return updatedBadges;
        });
      }
    } catch (error) {
      console.error('Error updating badges:', error);
    }
  };

  // Add these translations to the language names
  const getTranslatedLanguage = (language, t) => {
    // Convert to lowercase for consistent lookup
    const formattedLanguage = language.toLowerCase();
    return t(`languages.${formattedLanguage}`);
  };

  const renderBadges = () => {
    const translatedBadges = getTranslatedBadgeDetails(t);
    
    return (
      <div className="account-badges-container fade-in">
        <div className="account-badges-grid">
          {Object.keys(translatedBadges).map((badgeKey) => {
            const userBadge = badges.find((b) => b.badge === badgeKey);
            const badgeLevel = userBadge ? userBadge.level : 'locked';
            const badgeClassName = `account-badge-card ${badgeLevel}`;

            return (
              <div
                key={badgeKey}
                className={badgeClassName}
                onClick={() => handleBadgeClick(badgeKey)}
              >
                <img
                  src={translatedBadges[badgeKey].image}
                  alt={t(translatedBadges[badgeKey].name)}
                  className="account-badge-image"
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const handleProgressClick = () => {
    if (!user?.is_pro) {
      setShowProModal(true);
      return;
    }
    navigate('/progress');
  };

  return (
    <>
      <div className={`dashboard-page fade-in ${isTourActive ? 'tour-active' : ''}`}>
        {/* Include the Welcome Modal */}
        {showWelcomeModal && (
          <WelcomeModal
            isOpen={showWelcomeModal}
            onStartTour={handleStartTour}
            onClose={closeWelcomeModal}
          />
        )}

        {/* Tour setup */}
        
        {/* Rest of your Dashboard component */}
        <div className="languages-header fade-in">
          <h1 className="dashboard-page-h1">{t('dashboard.myLanguages')}</h1>
          <button
            onClick={openLanguageModal}
            className="add-language-button fade-in"
          >
            +
          </button>
        </div>
        
        <div className="languages-container-dash fade-in">
          {interests.map((language) => (
            <div
              key={language}
              className="language-card fade-in"
              onClick={() => handleLanguageClick(language)}
            >
              <img
                src={languageImages[language]}
                alt={getTranslatedLanguage(language, t)}
                className="language-image fade-in"
              />
              <div className="language-name fade-in">
                {getTranslatedLanguage(language, t)}
                {languageLevels[language] && (
                  <span className="language-level fade-in">
                    {languageLevels[language]}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        
        <h1 className="dashboard-page-h1-badges">{t('dashboard.badges')}</h1>
        {renderBadges()}
        
        {/* Language Selection Modal */}
        <Modal
          isOpen={languageModalIsOpen}
          onRequestClose={closeLanguageModal}
          contentLabel={t('dashboard.languageModal.title')}
          className="modal fade-in"
          overlayClassName="overlay fade-in"
        >
          <h2 className="fade-in">{t('dashboard.languageModal.title')}</h2>
          <div className="languages-container-modal fade-in">
            {availableLanguages.map((language) => (
              <div
                key={language}
                className={`language-item fade-in ${
                  selectedLanguages.includes(language) ? 'selected' : ''
                } ${interests.includes(language) ? 'disabled' : ''}`}
                onClick={() => toggleLanguageSelection(language)}
              >
                <img
                  src={languageImages[language]}
                  alt={getTranslatedLanguage(language, t)}
                  className="fade-in"
                />
                <p className="fade-in">{getTranslatedLanguage(language, t)}</p>
                {selectedLanguages.includes(language) && (
                  <span className="checkmark fade-in">✔️</span>
                )}
              </div>
            ))}
          </div>
          <button
            onClick={handleAddLanguages}
            className="add-languages-button fade-in"
          >
            {t('dashboard.languageModal.buttons.add')}
          </button>
          <button
            onClick={closeLanguageModal}
            className="close-modal-button fade-in"
          >
            {t('dashboard.languageModal.buttons.close')}
          </button>
        </Modal>

        <div 
          className="progress-preview-container fade-in"
          onClick={handleProgressClick}
        >
          <div className="progress-preview-text">
            <div className="progress-preview-title">
              {t('dashboard.progress.preview.title')}
            </div>
            <div className="progress-preview-description">
              {t('dashboard.progress.preview.description')}
            </div>
          </div>
          <div className="progress-preview-icon">
            📊
          </div>
        </div>
      </div>

      {/* Speakeasy Pro Button - outside the dashboard-page div */}
      <button className="speakeasy-pro-button acc-button" onClick={handleOpenProModal}>
        {t("Dashboard.learn.more.about.speakeasy.pro")}
      </button>

      {/* Speakeasy Pro Modal */}
      <SpeakeasyProModal
        isOpen={showProModal}
        onRequestClose={handleCloseProModal}
      />

      {/* Add this near the end of the return statement, just before the closing </> tag */}
      <BadgeModal
        isOpen={badgeModalIsOpen}
        onRequestClose={closeBadgeModal}
        badge={selectedBadge}
        badgeDetails={badgeDetails} // Pass badgeDetails here
      />
    </>
  );
}

export default Dashboard;
