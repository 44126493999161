import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import './AIConversation.css';
import openAIService from '../../services/openAIService';
import SpeakeasyProModal from '../SpeakeasyProModal/SpeakeasyProModal';

// Add near the top of the file, after imports
const stripRomanization = (text) => {
  if (!text) return '';  // Return empty string if text is undefined/null
  
  // Match entire phrases in Chinese/etc followed by romanization in parentheses
  return text.replace(
    /\s*\([^)]+\)/g, 
    ''
  ).trim();
};

// For debugging, you can add these tests:
/*
console.log(stripRomanization('你準備好未呀？(nei5 zeon2 bei2 hou2 mei6 aa3?)'));
// Should output: "
*/

// Add this near the top of your file with the tutorImages
const tutors = {
  spanish: {
    name: "Isabella Martínez",
    image: '/images/tutor-spanish.webp',
    title: "Spanish Language Expert",
    specialty: "Conversational Spanish"
  },
  french: {
    name: "Jacques Dupont",
    image: '/images/tutor-french.webp',
    title: "French Language Instructor",
    specialty: "Pronunciation & Grammar"
  },
  german: {
    name: "Klaus Weber",
    image: '/images/tutor-german.webp',
    title: "German Language Specialist",
    specialty: "Business German"
  },
  italian: {
    name: "Giulia Bianchi",
    image: '/images/tutor-italian.jpeg',
    title: "Italian Language Coach",
    specialty: "Cultural Immersion"
  },
  portuguese: {
    name: "Thiago Silva",
    image: '/images/tutor-brasil.webp',
    title: "Portuguese Instructor",
    specialty: "Brazilian Portuguese"
  },
  russian: {
    name: "Dmitri Volkov",
    image: '/images/tutor-russian.jpeg',
    title: "Russian Language Expert",
    specialty: "Conversational Russian"
  },
  japanese: {
    name: "Mariko Sama",
    image: '/images/tutor-japan.webp',
    title: "Japanese Language Sensei",
    specialty: "Daily Conversation"
  },
  mandarin: {
    name: "Liu Mei",
    image: '/images/tutor-mandarin.webp',
    title: "Mandarin Chinese Instructor",
    specialty: "Business Chinese"
  },
  arabic: {
    name: "Ahmed Hassan",
    image: '/images/tutor-arabic.webp',
    title: "Arabic Language Professor",
    specialty: "Modern Standard Arabic"
  },
  cantonese: {
    name: "Zi Tsing",
    image: '/images/tutor-cantonese.webp',
    title: "Cantonese Language Tutor",
    specialty: "Hong Kong Cantonese"
  },
  korean: {
    name: "Min-ji Kim",
    image: '/images/tutor-korea.webp',
    title: "Korean Language Coach",
    specialty: "K-Culture & Language"
  },
  tagalog: {
    name: "Maria Santos",
    image: '/images/tutor-spanish.webp',
    title: "Filipino Language Expert",
    specialty: "Conversational Tagalog"
  },
  hindi: {
    name: "Arjun Patel",
    image: '/images/tutor-spanish.webp',
    title: "Hindi Language Guru",
    specialty: "Modern Hindi"
  },
  greek: {
    name: "Alexandra Papachristos",
    image: '/images/tutor-greek.webp',
    title: "Greek Language Instructor",
    specialty: "Modern Greek"
  }
};

// Separate Welcome Screen Component
const WelcomeScreen = ({ onStart, language }) => {
  const { t } = useTranslation();
  
  const handleStart = () => {
    onStart();
  };
  
  const features = [
    {
      icon: '🎯',
      title: 'levelAdjusted',
      description: 'levelAdjustedDesc'
    },
    {
      icon: '🔄',
      title: 'translations',
      description: 'translationsDesc'
    },
    {
      icon: '✍️',
      title: 'corrections',
      description: 'correctionsDesc'
    }
  ];

  return (
    <>
      <div className="speakeasy-welcome-container">
        <div className="speakeasy-welcome-header">
          <h1>{t('languagePage.conversation.welcome.title')}</h1>
          <p>{t('languagePage.conversation.welcome.subtitle')}</p>
        </div>
        
        <div className="speakeasy-features-grid">
          {features.map((feature, index) => (
            <div key={index} className="speakeasy-feature-card">
              <div className="feature-icon">{feature.icon}</div>
              <h3>{t(`languagePage.conversation.features.${feature.title}`)}</h3>
              <p>{t(`languagePage.conversation.features.${feature.description}`)}</p>
            </div>
          ))}
        </div>

        <button 
          className="speakeasy-start-chat-btn"
          onClick={handleStart}
        >
          {t('languagePage.conversation.welcome.startButton')}
        </button>
      </div>
    </>
  );
};

// Simplified VOICE_CONFIG with WaveNet voices
const VOICE_CONFIG = {
  russian: { languageCode: 'ru-RU', name: 'ru-RU-Wavenet-D' },    // Male
  spanish: { languageCode: 'es-ES', name: 'es-ES-Wavenet-C' },    // Female
  japanese: { languageCode: 'ja-JP', name: 'ja-JP-Wavenet-B' },   // Female
  portuguese: { languageCode: 'pt-BR', name: 'pt-BR-Wavenet-B' }, // Male (Brazilian)
  french: { languageCode: 'fr-FR', name: 'fr-FR-Wavenet-B' },     // Female
  german: { languageCode: 'de-DE', name: 'de-DE-Wavenet-D' },     // Male
  italian: { languageCode: 'it-IT', name: 'it-IT-Wavenet-B' },    // Female
  mandarin: { languageCode: 'cmn-CN', name: 'cmn-CN-Wavenet-A' }, // Female
  arabic: { languageCode: 'ar-XA', name: 'ar-XA-Wavenet-B' },     // Male
  cantonese: { languageCode: 'yue-HK', name: 'yue-HK-Standard-A' }, // Female (Note: No WaveNet available)
  korean: { languageCode: 'ko-KR', name: 'ko-KR-Wavenet-A' },     // Female
  tagalog: { languageCode: 'fil-PH', name: 'fil-PH-Standard-A' }, // Female (Note: No WaveNet available)
  hindi: { languageCode: 'hi-IN', name: 'hi-IN-Wavenet-C' },      // Male
  greek: { languageCode: 'el-GR', name: 'el-GR-Wavenet-A' }       // Female
};

// Simplified Message component
const Message = ({ message, t, language }) => {
  const [isTranslating, setIsTranslating] = useState(false);
  const [translatedContent, setTranslatedContent] = useState(null);
  const [showTranslateHint, setShowTranslateHint] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleTranslate = async (text) => {
    setIsTranslating(true);
    try {
      console.log('Starting translation with Google Cloud API...');
      
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            q: text,
            target: 'en',
            format: 'text'
          })
        }
      );

      const data = await response.json();
      console.log('Translation response:', data);

      if (data.error) {
        console.error('Translation API error:', data.error);
        throw new Error(data.error.message);
      }

      setTranslatedContent(data.data.translations[0].translatedText);
    } catch (error) {
      console.error('Translation error:', error);
    } finally {
      setIsTranslating(false);
    }
  };

  const handleMessageClick = () => {
    if (message.role === 'assistant') {
      if (!translatedContent && !isTranslating) {
        setShowTranslateHint(true);
      }
    }
  };

  const handleTranslateClick = () => {
    if (showTranslateHint) {
      handleTranslate(message.content);
      setShowTranslateHint(false);
    }
  };

  const handleSpeak = useCallback(async () => {
    if (message.role !== 'assistant') return;
    
    try {
      setIsPlaying(true);
      const langConfig = VOICE_CONFIG[language.toLowerCase()];
      const textToSpeak = message.ttsContent || message.content || '';
      
      // Log voice configuration
      console.log('Using voice config:', {
        language: language.toLowerCase(),
        languageCode: langConfig?.languageCode,
        voiceName: langConfig?.name,
        usingTTS: Boolean(message.ttsContent),
        contentLength: textToSpeak.length
      });
      
      const response = await fetch(
        `https://texttospeech.googleapis.com/v1/text:synthesize?key=${process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            input: { text: textToSpeak },
            voice: {
              languageCode: langConfig?.languageCode || 'en-US',
              name: langConfig?.name || 'en-US-Wavenet-A'
            },
            audioConfig: { 
              audioEncoding: 'MP3',
              speakingRate: 1.0,
              pitch: 0
            }
          })
        }
      );

      if (!response.ok) {
        const error = await response.json();
        console.error('TTS API Error:', error);
        throw new Error(error.error?.message || 'TTS request failed');
      }

      const data = await response.json();
      const audio = new Audio(`data:audio/mp3;base64,${data.audioContent}`);
      audio.onended = () => setIsPlaying(false);
      await audio.play();
      
    } catch (error) {
      console.error('TTS error:', error);
      setIsPlaying(false);
    }
  }, [message.role, message.ttsContent, message.content, language]);

  useEffect(() => {
    if (message.role === 'assistant') {
      handleSpeak();
    }
  }, [message.role, handleSpeak]);

  return (
    <div className={`speakeasy-message ${message.role === 'user' ? 'user' : 'ai'}`}>
      {message.role === 'assistant' ? (
        <>
          <div className="message-wrapper">
            {!message.isLimitMessage ? (
              // Regular AI message with tutor info
              <>
                <div className="tutor-info">
                  <span className="tutor-name">{tutors[language.toLowerCase()]?.name}</span>
                  <span className="tutor-title">{tutors[language.toLowerCase()]?.title}</span>
                </div>
                <div className="message-content-wrapper">
                  <div className="ai-profile-pic">
                    <img src={tutors[language.toLowerCase()]?.image} alt={tutors[language.toLowerCase()]?.name} />
                  </div>
                  <div className="speakeasy-message-content clickable" onClick={handleMessageClick}>
                    {translatedContent || message.content}
                  </div>
                  <div className="voice-controls">
                    <button
                      className={`speak-button ${isPlaying ? 'playing' : ''}`}
                      onClick={handleSpeak}
                      disabled={!message.ttsContent}
                      title={isPlaying ? 'Stop' : 'Speak'}
                    >
                      {isPlaying ? '🔊' : '🔈'}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              // Limit reached message with special styling
              <div className="limit-message-wrapper">
                <div className="speakeasy-message-content limit-reached">
                  {message.content}
                </div>
              </div>
            )}
          </div>
          {showTranslateHint && !translatedContent && (
            <button className="translation-action-btn" onClick={handleTranslateClick}>
              🌐 Click to translate
            </button>
          )}
          {translatedContent && (
            <button className="translation-action-btn" onClick={() => setTranslatedContent(null)}>
              Show original
            </button>
          )}
        </>
      ) : (
        <div className="speakeasy-message-content">
          {message.content}
        </div>
      )}
    </div>
  );
};

// Main Component
const AIConversation = () => {
  const { language } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showProModal, setShowProModal] = useState(false);
  const messagesContainerRef = useRef(null);
  const level = location.state?.level || 'A1';
  const [isConversationStarted, setIsConversationStarted] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const recognition = useRef(null);
  const silenceTimeout = useRef(null);
  const SILENCE_DURATION = 3000; // 3 seconds of silence before stopping
  const initialMessage = useMemo(() => ({
    role: 'assistant',
    content: t('languagePage.conversation.chat.initialGreeting', {
      language: t(`languages.${language.toLowerCase()}`)
    })
  }), [language, t]);
  const [showLimitPopup, setShowLimitPopup] = useState(false);

  useEffect(() => {
    if (shouldScroll && messages.length > 0 && messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      const scrollHeight = container.scrollHeight;
      const height = container.clientHeight;
      const maxScroll = scrollHeight - height;
      
      const shouldAutoScroll = container.scrollTop > maxScroll - 100;
      if (shouldAutoScroll) {
        container.scrollTop = maxScroll;
      }
      setShouldScroll(false);
    }
  }, [messages, shouldScroll]);

  useEffect(() => {
    if (messages.length > 1) {
      localStorage.setItem(`chat_${language}_messages`, JSON.stringify(messages));
    }
  }, [messages, language]);

  useEffect(() => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognition.current = new SpeechRecognition();
      
      // Configure for continuous speech
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      recognition.current.lang = VOICE_CONFIG[language.toLowerCase()]?.languageCode || 'en-US';

      recognition.current.onstart = () => {
        console.log('Speech recognition started');
        setIsListening(true);
      };

      recognition.current.onresult = (event) => {
        // Clear any existing silence timeout
        if (silenceTimeout.current) {
          clearTimeout(silenceTimeout.current);
        }

        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        
        setInputMessage(transcript);

        // Set new silence timeout
        silenceTimeout.current = setTimeout(() => {
          if (recognition.current) {
            recognition.current.stop();
          }
        }, SILENCE_DURATION);
      };

      recognition.current.onspeechend = () => {
        console.log('Speech ended');
        // Don't immediately stop - wait for silence timeout
      };

      recognition.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        if (event.error !== 'no-speech') { // Ignore no-speech errors
          setIsListening(false);
          clearTimeout(silenceTimeout.current);
        }
      };

      recognition.current.onend = () => {
        console.log('Recognition ended');
        setIsListening(false);
        clearTimeout(silenceTimeout.current);
      };
    }

    return () => {
      if (silenceTimeout.current) {
        clearTimeout(silenceTimeout.current);
      }
      if (recognition.current) {
        recognition.current.onresult = null;
        recognition.current.onerror = null;
        recognition.current.onend = null;
      }
    };
  }, [language]);

  const toggleListening = useCallback(() => {
    if (!navigator.onLine) {
      alert(t('languagePage.conversation.chat.noInternetConnection'));
      return;
    }

    if (!recognition.current) {
      alert(t('languagePage.conversation.chat.browserNotSupported'));
      return;
    }

    if (isListening) {
      clearTimeout(silenceTimeout.current);
      recognition.current.stop();
    } else {
      try {
        recognition.current.start();
      } catch (error) {
        console.error('Speech recognition start error:', error);
      }
    }
  }, [isListening, t]);

  // Add a restart function for when recognition ends unexpectedly
  const restartRecognition = useCallback(() => {
    if (isListening && recognition.current) {
      try {
        recognition.current.start();
      } catch (error) {
        console.error('Error restarting recognition:', error);
      }
    }
  }, [isListening]);

  // Add error recovery
  useEffect(() => {
    if (isListening) {
      const recoveryInterval = setInterval(() => {
        if (!recognition.current || recognition.current.state === 'ended') {
          restartRecognition();
        }
      }, 250);

      return () => clearInterval(recoveryInterval);
    }
  }, [isListening, restartRecognition]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    const userMessage = {
      role: 'user',
      content: inputMessage,
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);
    setShouldScroll(true);

    try {
      const response = await openAIService.generateConversationResponse(
        [...messages, userMessage],
        language,
        level,
        user?.id,
        user?.is_pro
      );
      
      if (!response.success && response.error === 'daily_limit_reached') {
        // Show limit popup first
        setShowLimitPopup(true);
        
        // Show pro modal with a delay
        setTimeout(() => {
          setShowProModal(true);
        }, 400); // Delay pro modal appearance
        
        return;
      }

      // Check if we have a valid message
      if (!response.success || !response.message) {
        throw new Error(response.error || 'No message received');
      }

      // Process the AI message with safety checks
      const processedMessage = {
        role: 'assistant',
        content: response.message,
        ttsContent: response.message ? stripRomanization(response.message) : response.message
      };

      setMessages(prev => [...prev, processedMessage]);
      setShouldScroll(true);
    } catch (error) {
      console.error('Conversation error:', error);
      // Add the error message to the conversation
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: t('languagePage.conversation.chat.error'),
        ttsContent: t('languagePage.conversation.chat.error')
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseConversation = () => {
    setIsConversationStarted(false);
    setMessages([]);
  };

  const handleLimitPopupClose = () => {
    // Animate out the limit popup
    const limitPopup = document.querySelector('.limit-popup');
    if (limitPopup) {
      limitPopup.style.animation = 'limitPopupFadeOut 0.6s ease forwards';
    }
    
    // Remove the popup after animation
    setTimeout(() => {
      setShowLimitPopup(false);
    }, 600);
  };

  return isConversationStarted ? (
    <div className="speakeasy-conversation">
      <div className="speakeasy-main">
        <div className="speakeasy-conversation-header">
          <div className="conversation-info">
            <h2 className="conversation-title">
              {t('languagePage.conversation.chat.title', { 
                language: t(`languages.${language.toLowerCase()}`)
              })}
            </h2>
            <div className="conversation-subtitle">
              {t('languagePage.conversation.chat.levelPrefix')} {level}
            </div>
          </div>
          <button 
            className="close-conversation-btn"
            onClick={handleCloseConversation}
            title={t('common.close')}
          >
            ×
          </button>
        </div>

        <div className="speakeasy-messages" ref={messagesContainerRef}>
          <div className="speakeasy-message ai">
            <div className="message-wrapper">
              <div className="tutor-info">
                <span className="tutor-name">{tutors[language.toLowerCase()]?.name}</span>
                <span className="tutor-title">{tutors[language.toLowerCase()]?.title}</span>
              </div>
              <div className="message-content-wrapper">
                <div className="ai-profile-pic">
                  <img 
                    src={tutors[language.toLowerCase()]?.image} 
                    alt={tutors[language.toLowerCase()]?.name} 
                  />
                </div>
                <div className="speakeasy-message-content">
                  {initialMessage.content}
                </div>
              </div>
            </div>
          </div>
          {messages.map((message, index) => (
            <Message 
              key={index} 
              message={message}
              t={t} 
              language={language} 
            />
          ))}
          
          {isLoading && (
            <div className="speakeasy-message ai">
              <div className="message-wrapper">
                <div className="tutor-info">
                  <span className="tutor-name">{tutors[language.toLowerCase()]?.name}</span>
                  <span className="tutor-title">{tutors[language.toLowerCase()]?.title}</span>
                </div>
                <div className="message-content-wrapper">
                  <div className="ai-profile-pic">
                    <img 
                      src={tutors[language.toLowerCase()]?.image} 
                      alt={tutors[language.toLowerCase()]?.name} 
                    />
                  </div>
                  <div className="speakeasy-message-content speakeasy-typing">
                    <span>{t('languagePage.conversation.chat.typing')}</span>
                    <div className="speakeasy-typing-dots">
                      <div className="speakeasy-typing-dot"></div>
                      <div className="speakeasy-typing-dot"></div>
                      <div className="speakeasy-typing-dot"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <form className="speakeasy-input-container" onSubmit={handleSendMessage}>
          <div className="input-wrapper">
            <input
              type="text"
              className="speakeasy-input"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder={isListening ? t('languagePage.conversation.chat.listening') : t('languagePage.conversation.chat.placeholder')}
              disabled={isLoading}
            />
            <button
              type="button"
              className={`speakeasy-mic-btn ${isListening ? 'listening' : ''}`}
              onClick={toggleListening}
              disabled={isLoading}
              title={isListening ? t('languagePage.conversation.chat.stopListening') : t('languagePage.conversation.chat.startListening')}
            >
              {isListening ? '🎤 ' + t('languagePage.conversation.chat.listening') : '🎙️'}
            </button>
          </div>
          <button 
            type="submit" 
            className="speakeasy-send-btn"
            disabled={isLoading || !inputMessage.trim()}
          >
            {t('languagePage.conversation.chat.send')}
          </button>
        </form>
      </div>

      <div className="speakeasy-tips">
        <h3>{t('languagePage.conversation.tips.title')}</h3>
        <ul>
          <li>{t('languagePage.conversation.tips.tip1')}</li>
          <li>{t('languagePage.conversation.tips.tip2')}</li>
          <li>{t('languagePage.conversation.tips.tip3')}</li>
        </ul>
      </div>

      <SpeakeasyProModal 
        isOpen={showProModal}
        onRequestClose={() => setShowProModal(false)}
        className="pro-modal"
        style={{
          overlay: {
            zIndex: 2000,  // Lower than limit popup
            backgroundColor: 'rgba(15, 14, 23, 0.8)',
            backdropFilter: 'blur(4px)'
          },
          content: {
            zIndex: 2001  // Still lower than limit popup (3001)
          }
        }}
      />
      {showLimitPopup && (
        <>
          <div className="modal-overlay" />
          <div className="limit-popup">
            <div className="limit-popup-content">
              {t('languagePage.conversation.chat.limitReached.message')}
            </div>
            <button 
              className="limit-popup-button"
              onClick={handleLimitPopupClose}
            >
              {t('languagePage.conversation.chat.limitReached.cta')}
            </button>
          </div>
        </>
      )}
    </div>
  ) : (
    <WelcomeScreen onStart={() => setIsConversationStarted(true)} language={language} />
  );
};

export default AIConversation; 