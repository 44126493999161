import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Register.css';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const languageImages = {
  Spanish: '/images/mexico_flag.png',
  Japanese: '/images/icon_flag-ja.svg',
  Portuguese: '/images/icon_flag-pt.svg',
  French: '/images/icon_flag-fr.svg',
  German: '/images/icon_flag-de.svg',
  Italian: '/images/icon_flag-it.svg',
  Mandarin: '/images/icon_flag-zh.svg',
  Arabic: '/images/icon_flag-ar.svg',
  Cantonese: '/images/icon_flag-hk.png',
  Korean: '/images/icon_flag-ko.svg',
  Russian: '/images/icon_flag-ru.svg',
  Tagalog: '/images/icon_flag_pp.png',
  Greek: '/images/icon_flag_gr.png',
  Hindi: '/images/icon_flag_in.png',
};

function Register() {
  const location = useLocation();
  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    display_name: '',
    email: new URLSearchParams(location.search).get('email') || '',
    password: '',
    interests: location.state?.selectedLanguages || [],
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [submitError, setSubmitError] = useState('');

  const backgroundVideos = useMemo(() => [
    '../../videos/stock5.mp4',
    '../../videos/stock4.mp4',
  ], []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentVideoIndex === backgroundVideos.length - 1) {
        const slider = document.querySelector('.video-slider');
        if (slider) {
          slider.style.transition = 'none';
          setCurrentVideoIndex(0);
          setTimeout(() => {
            slider.style.transition = 'transform 1.5s ease-in-out';
          }, 50);
        }
      } else {
        setCurrentVideoIndex(prev => prev + 1);
      }
    }, 8000);

    return () => clearInterval(interval);
  }, [currentVideoIndex, backgroundVideos.length]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateUsername = (username) => {
    const regex = /^[a-zA-Z0-9]{3,15}$/; // Only letters and numbers, no special characters or periods
    if (!regex.test(username)) {
      return "Username must be 3-15 characters long and can only contain letters and numbers.";
    }
    return null;
  };

  const validatePassword = (password) => {
    if (password.length < 6) {
      return "Password must be at least 6 characters long.";
    }
    return null;
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    let error = null;
    if (name === 'display_name') {
      error = validateUsername(value);
    } else if (name === 'password') {
      error = validatePassword(value);
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitError('');
    
    if (Object.values(errors).some((error) => error)) {
      setSubmitError("Please fix the form errors before submitting.");
      return;
    }

    fetch(`${apiUrl}/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          login(data.user);
          navigate('/home');
        } else {
          setSubmitError(data.message || 'Registration failed. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error registering user:', error);
        setSubmitError('An unexpected error occurred. Please try again later.');
      });
  };

  // Comment out the function
  /*
  const handleOAuth = (provider) => {
    const languagesParam = formData.interests.length > 0 ? formData.interests.join(',') : '';
    window.location.href = `${apiUrl}/auth/${provider}?register=true&languages=${languagesParam}`;
  };
  */

  return (
    <div className="register-container">
      <div className="register-left">
        <div 
          className="video-slider" 
          style={{ transform: `translateX(-${currentVideoIndex * 33.35}%)` }}
        >
          {backgroundVideos.map((video, index) => (
            <div key={video} className="video-slide">
              <video
                className="sign-in-hero-video"
                autoPlay
                muted
                loop
                playsInline
              >
                <source src={video} type="video/mp4" />
              </video>
            </div>
          ))}
        </div>
        <div className="register-left-content"></div>
      </div>
      <div className="register-right">
        <Link to="/home">
          <img src="/images/speakeasylogo.png" alt="SpeakEasy Logo" className="sign-in-logo" />
        </Link>
        <h2 className="register-title">Join Speakeasy</h2>
        {submitError && (
          <div className="error-message">
            {submitError}
          </div>
        )}
        <form onSubmit={handleSubmit} className="register-form">
          <div className="register-form-group">
            <label htmlFor="display_name" className="sign-in-label">Display Name:</label>
            <input
              type="text"
              id="display_name"
              name="display_name"
              value={formData.display_name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              className="sign-in-input"
            />
            {errors.display_name && <div className="tooltip">{errors.display_name}</div>}
          </div>
          <div className="register-form-group">
            <label htmlFor="email" className="sign-in-label">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="sign-in-input"
            />
          </div>
          <div className="register-form-group">
            <label htmlFor="password" className="sign-in-label">Password:</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                className="sign-in-input"
              />
              <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {errors.password && <div className="tooltip">{errors.password}</div>}
          </div>

          <h3 className="register-languages-title">Your Selected Languages</h3>
          <div className="register-languages">
            {formData.interests.map((language) => (
              <div key={language} className="register-language-item">
                <img src={languageImages[language]} alt={language} className="register-language-img" />
                <p>{language}</p>
              </div>
            ))}
          </div>

          <button type="submit" className="register-get-started-button" disabled={Object.values(errors).some((error) => error)}>
            Sign Up
          </button>
        </form>

        {/*
        <div className="register-or-divider">
          <span>OR</span>
        </div>

        <div className="register-oauth-buttons">
          <div className="register-oauth-button" onClick={() => handleOAuth('google')}>
            <img src="/images/google-icon.webp" alt="Google" className="register-oauth-icon" />
            Continue with Google
          </div>
        </div>
        */}

        <div className="register-signin-link">
          <span>Already have an account? </span>
          <Link to="/signin" className="register-signin-link-text">Sign In</Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
