import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from "react";
import { collection, addDoc, onSnapshot, query, orderBy, serverTimestamp, updateDoc, doc, arrayUnion, setDoc } from "firebase/firestore";
import { firestore } from "../../config/firebase";
import { AuthContext } from "../../contexts/AuthContext";
import { useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faCog, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import "./Chat.css";
import MembersModal from "./MembersModal";
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_BASE_URL;

// Add this function outside the effect
const createUserStatus = async (userId) => {
  const userStatusRef = doc(firestore, "userStatus", userId.toString());
  try {
    // Only set initial status if document doesn't exist
    const statusData = {
      userId: userId.toString(),
      lastSeen: serverTimestamp()
    };
    
    await setDoc(userStatusRef, statusData, { merge: true });
    console.log(`Initial status created for user ${userId}`);
  } catch (error) {
    console.error(`Error creating status for user ${userId}:`, error);
  }
};

const DirectMessages = () => {
  const { chatRoomId } = useParams(); // For Direct Messages
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const { user } = useContext(AuthContext);
  const [isTyping, setIsTyping] = useState(false);
  const [replyToMessage, setReplyToMessage] = useState(null);
  const chatMessagesRef = useRef(null);
  const typingTimeout = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showReactions, setShowReactions] = useState(null);
  const [activeMessageId, setActiveMessageId] = useState(null);
  const { t } = useTranslation();
  const [fullMemberDetails, setFullMemberDetails] = useState({});
  const location = useLocation();
  const [participants, setParticipants] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState({});

  // Update the participants effect
  useEffect(() => {
    if (chatRoomId) {
      const conversationRef = doc(firestore, "conversations", chatRoomId);
      const unsubscribe = onSnapshot(conversationRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const participantIds = docSnapshot.data().participants || [];
          setParticipants(participantIds);

          // Create status documents for all participants
          participantIds.forEach(participantId => {
            createUserStatus(participantId);
          });
        }
      });
      return () => unsubscribe();
    }
  }, [chatRoomId]);

  // Helper function to get other user's ID in DM
  const getOtherUserId = () => {
    console.log('Participants:', participants);
    console.log('Current user:', user.id);
    
    // Find the other participant's ID
    const otherId = participants.find(id => id !== user.id);
    console.log('Other user ID:', otherId);
    
    return otherId;
  };

  // const otherUserId = getOtherUserId();

  // Fetching messages logic
  useEffect(() => {
    const fetchMessages = () => {
      const conversationsRef = collection(firestore, "conversations", chatRoomId, "messages");
      const q = query(
        conversationsRef,
        orderBy("timestamp", "asc")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setMessages(fetchedMessages);
      });

      return () => unsubscribe();
    };

    if (chatRoomId) {
      fetchMessages();
    }
  }, [chatRoomId]);

  // Disable scrolling
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  // Function to format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    
    const date = new Date(timestamp.seconds * 1000);
    const now = new Date();
    const diffDays = (now - date) / (1000 * 60 * 60 * 24);
    
    if (diffDays < 1) {
      // If less than a day ago, show time
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      // If more than a day ago, show date
      return date.toLocaleDateString();
    }
  };

  const handleTyping = (e) => {
    setNewMessage(e.target.value);
    setIsTyping(true);

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }

    typingTimeout.current = setTimeout(() => setIsTyping(false), 1000);
  };

  const sendMessage = async () => {
    if (newMessage.trim() && user) {
      const userProfile = {
        user_id: user.id,
        display_name: user.display_name,
        profile_picture: user.profile_picture,
      };

      const messageData = {
        text: newMessage,
        timestamp: serverTimestamp(),
        ...userProfile,
        environment: process.env.REACT_APP_ENV === "prod" ? "" : "_local",
        chatRoomId: chatRoomId,
      };

      try {
        // Handle reply notification
        if (replyToMessage) {
          messageData.replyTo = {
            id: replyToMessage.id,
            text: replyToMessage.text,
            display_name: replyToMessage.display_name,
          };

          // Send reply notification if replying to another user's message
          if (replyToMessage.user_id !== user.id) {
            await fetch(`${apiUrl}/send_chat_reply_notification`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                to_user_id: replyToMessage.user_id,
                from_user_name: user.display_name,
                language: 'Direct Message'
              }),
            });
          }
        }

        // Add message to messages subcollection
        await addDoc(
          collection(firestore, "conversations", chatRoomId, "messages"),
          messageData
        );

        // Update conversation document
        const conversationRef = doc(firestore, "conversations", chatRoomId);
        await updateDoc(conversationRef, {
          lastMessage: newMessage,
          lastMessageTimestamp: serverTimestamp(),
        });

        // Send new message notification if recipient isn't on this chat page
        const otherUserId = getOtherUserId();
        if (otherUserId) {
          const otherUserStatusId = otherUserId.toString();
          const otherUserStatus = onlineUsers[otherUserStatusId];

          // Log detailed status before making decision
          console.log('Detailed status check:', {
            otherUser: {
              id: otherUserId,
              online: otherUserStatus?.online,
              path: otherUserStatus?.currentPath
            },
            currentUser: {
              id: user.id,
              path: location.pathname
            },
            chatRoom: {
              id: chatRoomId,
              expectedPath: `/direct/${chatRoomId}`
            }
          });

          const isUserInChat = Boolean(
            otherUserStatus?.online && 
            otherUserStatus?.currentPath === `/direct/${chatRoomId}`
          );

          console.log('Final decision:', {
            isUserInChat,
            willSendNotification: !isUserInChat,
            reason: isUserInChat ? 
              'User is active in chat' : 
              `User is ${otherUserStatus?.online ? 'online but not in chat' : 'offline'}`
          });

          if (!isUserInChat) {
            try {
              const response = await fetch(`${apiUrl}/create_notification`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                },
                body: JSON.stringify({
                  type: 'new_dm',
                  content: `${user.display_name} sent you a DM`,
                  user_id: otherUserId,
                  chatRoomId: chatRoomId
                }),
              });
              
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
            } catch (error) {
              console.error('Notification error:', error);
            }
          }
        }

        setNewMessage("");
        setReplyToMessage(null);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const addReaction = async (messageId, reaction) => {
    const messageRef = doc(firestore, "conversations", chatRoomId, "messages", messageId);
    try {
      await updateDoc(messageRef, {
        reactions: arrayUnion({ user_id: user.id, reaction }),
      });
    } catch (error) {
      console.error("Error adding reaction:", error);
    }
    setShowReactions(null);
  };

  const handleReply = (message) => {
    setReplyToMessage(message);
  };

  const getProfilePictureSrc = (profilePicture) => {
    if (profilePicture.startsWith("http")) {
      return profilePicture;
    }
    return `http://127.0.0.1:5000/${profilePicture}`;
  };

  const uniqueMembers = useMemo(() => {
    return [...new Map(messages.map((msg) => [msg.user_id, msg])).values()];
  }, [messages]);

  const toggleModal = async () => {
    if (!isModalOpen && uniqueMembers.length > 0) {
      const memberIds = uniqueMembers.map(member => member.user_id);
      const details = await fetchFullMemberDetails(memberIds);
      setFullMemberDetails(details);
    }
    setIsModalOpen(!isModalOpen);
  };

  const fetchFullMemberDetails = useCallback(async (memberIds) => {
    try {
      const response = await fetch(`${apiUrl}/get_members_modals_details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_ids: memberIds }),
      });
      const data = await response.json();
      if (data.success) {
        return data.users.reduce((acc, user) => {
          acc[user.id] = user;
          return acc;
        }, {});
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
    return {};
  }, []);

  const membersWithStats = useMemo(() => {
    return uniqueMembers.map((member) => {
      const messageCount = messages.filter((msg) => msg.user_id === member.user_id).length;
      const fullDetails = fullMemberDetails[member.user_id] || {};
      return {
        ...member,
        ...fullDetails,
        messageCount,
        isOnline: true,
      };
    });
  }, [uniqueMembers, messages, fullMemberDetails]);

  const handleReactionClick = (msgId) => {
    setShowReactions(msgId);
    setTimeout(() => {
      setShowReactions(null);
    }, 3000);
  };

  // Add this useEffect at the top of your component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Move updateStatus inside the effect where it's used
  useEffect(() => {
    if (user) {
      const userStatusRef = doc(firestore, "userStatus", user.id.toString());
      
      const updateStatus = async () => {
        const statusData = {
          online: true,  // Always true when user is active
          currentPath: location.pathname,
          lastSeen: serverTimestamp(),
          userId: user.id.toString(),
          displayName: user.display_name
        };

        try {
          await setDoc(userStatusRef, statusData);
          console.log('Online status updated:', statusData);
        } catch (error) {
          console.error('Error updating online status:', error);
        }
      };

      // Update status immediately when component mounts
      updateStatus();

      // Set up auto-refresh interval to keep status active
      const intervalId = setInterval(updateStatus, 30000); // Update every 30 seconds

      // Set up listener for all users' status
      const statusQuery = query(collection(firestore, "userStatus"));
      const unsubscribe = onSnapshot(statusQuery, (snapshot) => {
        const statuses = {};
        snapshot.forEach((doc) => {
          statuses[doc.id] = doc.data();
        });
        setOnlineUsers(statuses);
        console.log('Status updated for all users:', 
          Object.entries(statuses).map(([id, status]) => ({
            userId: id,
            online: status.online,
            path: status.currentPath
          }))
        );
      });

      // Cleanup
      return () => {
        console.log('Cleaning up presence for user:', user.id);
        setDoc(userStatusRef, {
          online: false,
          currentPath: null,
          lastSeen: serverTimestamp()
        });
        unsubscribe();
        clearInterval(intervalId);
      };
    }
  }, [user, location.pathname]); // updateStatus is now inside the effect

  return (
    <div className="chat-page-container">
      <div className="chat-container">
        <div className="header">
          <div className="chat-title">
            <h1>{t('directMessages.title')}</h1>
          </div>
          <div className="chat-info">
          </div>
        </div>

        <div className="chat-messages-container">
          <div className="members-navbar">
            <div className="left-section">
              <span className="title" onClick={toggleModal}>
                {t('directMessages.members')}
              </span>
              {uniqueMembers.map((member, index) => (
                <img
                  key={index}
                  src={getProfilePictureSrc(member.profile_picture)}
                  alt={member.display_name}
                  className="attendee-profile-pic"
                />
              ))}
            </div>
            <div className="right-section">
              <FontAwesomeIcon icon={faCog} />
            </div>
          </div>

          <div className="chat-messages" ref={chatMessagesRef}>
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`chat-message ${msg.user_id === user.id ? "own-message" : ""} ${
                  activeMessageId === msg.id ? "active" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveMessageId(msg.id);
                }}
              >
                <img src={getProfilePictureSrc(msg.profile_picture)} alt={msg.display_name} className="profile-pic" />
                <div className="message-content">
                  {msg.replyTo && (
                    <div className="reply-context">
                      <span className="reply-display-name">{msg.replyTo.display_name}</span>: {msg.replyTo.text}
                    </div>
                  )}
                  <span className="display-name">{msg.display_name}</span>
                  <span>{msg.text}</span>
                  {msg.timestamp && (
                    <span className="timestamp">{formatTimestamp(msg.timestamp)}</span>
                  )}
                  {msg.reactions && (
                    <div className="message-reactions">
                      {msg.reactions.map((reaction, index) => (
                        <span key={index} className="reaction">
                          {reaction.reaction}
                        </span>
                      ))}
                    </div>
                  )}
                  {activeMessageId === msg.id && (
                    <div className="message-options">
                      <FontAwesomeIcon icon={faEllipsisH} onClick={() => handleReactionClick(msg.id)} />
                      {showReactions === msg.id && (
                        <div className="reactions">
                          {["👍", "❤️", "😂", "😮", "😢"].map((reaction) => (
                            <span
                              key={reaction}
                              className="reaction-icon"
                              onClick={() => addReaction(msg.id, reaction)}
                            >
                              {reaction}
                            </span>
                          ))}
                          <button onClick={() => handleReply(msg)}>{t('directMessages.reactions.reply')}</button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
            {isTyping && <div className="typing-indicator">{t('directMessages.input.typing')}</div>}
          </div>

          <div className="chat-input-container">
            {replyToMessage && (
              <div className="replying-to">
                {t('directMessages.reply.prefix')} {replyToMessage.display_name} - {replyToMessage.text}
                <button onClick={() => setReplyToMessage(null)}>{t('directMessages.reply.cancel')}</button>
              </div>
            )}
            <div className="chat-input">
              <input
                type="text"
                value={newMessage}
                onChange={handleTyping}
                onKeyPress={handleKeyPress}
                placeholder={t('directMessages.input.placeholder')}
              />
              <button className="send-button" onClick={sendMessage}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>
          </div>
        </div>

        <div className="footer">
        </div>

        <MembersModal 
          isOpen={isModalOpen} 
          onClose={toggleModal} 
          members={membersWithStats} 
          fullMemberDetails={fullMemberDetails}
        />
      </div>
    </div>
  );
};

export default DirectMessages;
